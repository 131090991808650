import { UseQueryResult, useQuery } from '@tanstack/react-query';

import { FeatureFlag } from '../../types';
import { useFeatureFlagIds } from './useFeatureFlagIds';
import { corePresentApi } from '../../api/CorePresentApi';

const fetchFeatureFlags = async (accountId?: number): Promise<FeatureFlag[]> => {
  const featureFlags = await corePresentApi.get(`/accountDetail/featureFlags/${accountId}`);

  return featureFlags;
};

export function useAccountFeatureFlags(): UseQueryResult<FeatureFlag[]> {
  const ffIds = useFeatureFlagIds();

  const isSsrPage = window.location.href.includes('/ssr/');

  return useQuery(
    ['feature-flags', 'account', ffIds.accountId],
    () => fetchFeatureFlags(ffIds.accountId),
    {
      enabled: Boolean(ffIds.accountId),
      refetchInterval: 10 * 60 * 1000,
      refetchIntervalInBackground: true,
      refetchOnMount: false,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
      staleTime: 60 * 60 * 1000,
      retry: isSsrPage ? 0 : 3,
    },
  );
}
