import { PropertyType, TSubject, TSubjectAPI } from '../../../types';
import { AddressAdapter } from './AddressAdapter';

export class SubjectPropertyAdapter {
  static apiToEntity(apiProperty: TSubjectAPI): TSubject {
    return {
      id: apiProperty.id,
      address: AddressAdapter.apiToEntity(apiProperty.full_address),
      propertyType: apiProperty.property_type as PropertyType,
      size: apiProperty.size,
      totalSize: apiProperty.total_size,
      beds: apiProperty.beds,
      totalBaths: apiProperty.total_baths,
      baths: apiProperty.baths,
      yearBuilt: apiProperty.year_built,
      coordinates: apiProperty.coordinates,
    };
  }
}
