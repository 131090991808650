import { useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import { AddressCommunicator } from '../../../../../../pages/PresentationCreate/dash/communicators/Address/AddressCommunicator';
import { Autocomplete } from '../../../../../../components/Common/Autocomplete/Autocomplete';
import { useUser } from '../../../../../../components/Slide/providers/UserProvider';
import { TAddress } from '../../../../../../types';
import { statesList } from './RequiredFields';
import { PropertyCommunicator } from '../../../../../../pages/PresentationCreate/dash/communicators/Property/PropertyCommunicator';
import { propertyTypeOptions } from '../../../../../../pages/PresentationCreate/dash/view/pages/Authenticated/PropertySearch/PropertyDetails/PropertySearchCriteria/Criterias/PropertyType/propertyTypeOptions';

interface AddressOption extends TAddress {
  label: string;
  value: string;
}

export const AutosuggestAddresses = () => {
  const [addressOptions, setAddressOptions] = useState<AddressOption[]>([]);

  const {
    control,
    setValue,
    clearErrors,
    formState: { errors },
  } = useFormContext();

  const { isCanadaUser } = useUser();

  const getAddressOptions = async (address: string) => {
    if (isCanadaUser) {
      return [];
    }
    const addresses: TAddress[] = await AddressCommunicator.search(address);

    const autocompleteAddresses = addresses.map((address: TAddress) => ({
      ...address,
      label: address.fullAddress,
      value: address.fullAddress,
    }));

    setAddressOptions(autocompleteAddresses);
  };

  const onAddressChange = async (addressQuery: string) => {
    const addressOption = addressOptions.find(({ value }) => value === addressQuery);

    if (!addressOption) {
      return;
    }

    const property = await PropertyCommunicator.search({
      deliveryLine: addressOption.deliveryLine,
      // number: addressOption.number,
      city: addressOption.city,
      state: addressOption.state,
      external: true,
    });

    const state = statesList.find(({ value }) => value === addressOption.state) || null;

    if (addressOption.city) {
      setValue('city', addressOption.city);
      clearErrors('city');
    }
    if (state) {
      setValue('state', state);
      clearErrors('state');
    }
    if (property.address.zip) {
      setValue('zip', property.address.zip);
      clearErrors('zip');
    }
    if (property.propertyType) {
      const propertyType =
        propertyTypeOptions.find(({ value }) => value === property.propertyType) || null;
      setValue('propertyType', propertyType);
      clearErrors('propertyType');
    }
    if (property.price) {
      setValue('listPrice', property.price);
      clearErrors('listPrice');
    }
    if (property.salePrice) {
      setValue('soldPrice', property.salePrice);
      clearErrors('soldPrice');
    }
    if (property.size) {
      setValue('livingAreaSqft', property.size);
      clearErrors('livingAreaSqft');
    }
  };

  return (
    <Controller
      name='address'
      control={control}
      render={({ field }) => (
        <Autocomplete
          {...field}
          label='Address:'
          required
          onChange={e => {
            field.onChange(e);
            onAddressChange(e);
          }}
          suggestions={addressOptions}
          asyncOptionsProvider={getAddressOptions}
          error={errors.address ? (errors.address.message as string) : undefined}
          hideErrorMessage
        />
      )}
    />
  );
};
