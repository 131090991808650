import {
  COMPARABLE_MODAL_MENU_ITEM_ID,
  COMPARABLE_MODAL_MENU_SUBITEM_ID,
} from '../../../../../types';

type MenuSubItem = {
  id: COMPARABLE_MODAL_MENU_SUBITEM_ID;
  title: string;
};

type MenuMainItem = {
  id: COMPARABLE_MODAL_MENU_ITEM_ID;
  title: string;
  subItems?: MenuSubItem[];
};

export const sideMenuItems: MenuMainItem[] = [
  {
    id: COMPARABLE_MODAL_MENU_ITEM_ID.REQUIRED_FIELDS,
    title: 'Required Fields',
  },
  {
    id: COMPARABLE_MODAL_MENU_ITEM_ID.OPTIONAL_FIELDS,
    title: 'Optional Fields',
    subItems: [
      {
        id: COMPARABLE_MODAL_MENU_SUBITEM_ID.MAIN_FEATURES,
        title: 'Main Features',
      },
      {
        id: COMPARABLE_MODAL_MENU_SUBITEM_ID.INTERIOR_FEATURES,
        title: 'Interior Features',
      },
      {
        id: COMPARABLE_MODAL_MENU_SUBITEM_ID.EXTERIOR_FEATURES,
        title: 'Exterior Features',
      },
      {
        id: COMPARABLE_MODAL_MENU_SUBITEM_ID.OTHER_FEATURES,
        title: 'Other Features',
      },
      {
        id: COMPARABLE_MODAL_MENU_SUBITEM_ID.DESCRIPTION,
        title: 'Description',
      },
      {
        id: COMPARABLE_MODAL_MENU_SUBITEM_ID.VIRTUAL_TOUR,
        title: 'Virtual & 3D Tour',
      },
      {
        id: COMPARABLE_MODAL_MENU_SUBITEM_ID.UPLOAD_IMAGE,
        title: 'Upload Image',
      },
    ],
  },
];
