import { manualAddressAtom } from '../state/manualAddressAtom';
import { SubjectManualEntry } from './subject-manual-entry/SubjectManualEntry';
import { PropertyAddressForm } from './PropertyAddressForm';
import { SubjectInfoOverride } from './subject-info-override/SubjectInfoOverride';
import { Dispatch, SetStateAction, useState } from 'react';
import { useAtom } from 'jotai';
import { useHistory, useLocation } from 'react-router-dom';
import { AuthenticatedRoutes } from '../../../../pages/Router/Routes';

interface Props {
  overridePropertyStep: boolean;
  setOverridePropertyStep: Dispatch<SetStateAction<boolean>>;
}

export const SubjectPropertyForm = ({ overridePropertyStep, setOverridePropertyStep }: Props) => {
  const [manualAddress, setManualAddress] = useAtom(manualAddressAtom);
  const [clearAddressForm, setClearAddressForm] = useState(false);

  const location = useLocation();
  const history = useHistory();
  const query = new URLSearchParams(location.search);

  const onPrevious = () => {
    setOverridePropertyStep(false);
    setClearAddressForm(true);

    history.push({
      pathname: AuthenticatedRoutes.build,
      search: query.has('type') ? `?type=${query.get('type')}` : '',
    });
  };

  if (manualAddress) return <SubjectManualEntry />;

  return (
    <>
      <PropertyAddressForm
        disableFooter={overridePropertyStep}
        onNonExistentAddress={setManualAddress}
        clearAddressForm={clearAddressForm}
        setClearAddressForm={setClearAddressForm}
        setOverridePropertyStep={setOverridePropertyStep}
      />
      {overridePropertyStep && <SubjectInfoOverride onPrevious={onPrevious} />}
    </>
  );
};
