import { ERROR_TYPE, TError } from '../../../../../../../types';

const customMessage =
  'We had trouble building partial report. Please try again. If the issue persists, please contact our support team.';

export const getErrorMessage = (error: TError) => {
  if (error?.error === ERROR_TYPE.LISTINGS_COUNT_EXCEEDED) {
    return error?.message || customMessage;
  }

  return customMessage;
};
