import { useSetAtom } from 'jotai';
import { useState, useEffect, FC } from 'react';
import styled from 'styled-components';

import { BuildCMAActions } from '../../../pages/PresentationCreate/BuildCMAActions';
import { useRebuildUtils } from '../../../hooks/useRebuildUtils';
import { FullScreenLoader } from '../../../components/Loader/FullScreenLoader';
import { withLoadedUser } from '../../../hoc/withLoadedUser';
import { UiConfigProvider } from '../../../providers/providers/UiConfigProvider';
import { usePresentationCreationQuery } from '../../../hooks/usePresentationCreationQuery';
import { PresentationBuildingLayout } from '../../../layouts/PresentationBuildingLayout/PresentationBuildingLayout';
import { isPresentationViewAtom } from '../../report/state/isPresentationViewAtom';

export const PresentationCreationStepperLayout: FC = withLoadedUser(({ children }) => {
  const { fetchPresentationAndSetToStore } = useRebuildUtils();
  const { type, isRebuild, presentation } = usePresentationCreationQuery();
  const setIsPresentationView = useSetAtom(isPresentationViewAtom);

  const [isFetchingRebuild, setIsFetchingRebuild] = useState(true);

  useEffect(() => {
    window.scrollTo(0, 0);
    setIsPresentationView(true);
  }, [setIsPresentationView]);

  useEffect(() => {
    (async () => {
      if (isRebuild && presentation && isFetchingRebuild) {
        await fetchPresentationAndSetToStore(presentation, type);
      }

      setIsFetchingRebuild(false);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (isFetchingRebuild) {
    return <FullScreenLoader />;
  }

  return (
    <UiConfigProvider>
      <PresentationBuildingLayout HeaderActions={<BuildCMAActions />} isScrollable>
        <Wrapper>{children}</Wrapper>
      </PresentationBuildingLayout>
    </UiConfigProvider>
  );
});

const Wrapper = styled.div`
  padding: 30px;
  min-height: calc(100vh - 70px);
  background: ${({ theme }) => theme.colors.v2.gradient.background};
`;
