import { useAtomValue } from 'jotai';
import { manualAddressAtom } from '../../state/manualAddressAtom';
import { GoogleMapComponent } from '../../../../../components/Common/GoogleMap/GoogleMap.component';
import { Marker } from '@react-google-maps/api';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import styled from 'styled-components';
import { useMarkerPositionFromAddress } from './hooks/useMarkerPositionFromAddress';

interface Props {
  markerPosition: google.maps.LatLngLiteral | null;
  setMarkerPosition: Dispatch<SetStateAction<google.maps.LatLngLiteral | null>>;
}

export const ManualEntryMap = ({ markerPosition, setMarkerPosition }: Props) => {
  const manualAddress = useAtomValue(manualAddressAtom);

  const [defaultCenter, setDefaultCenter] = useState({ lat: 36.778259, lng: -119.417931 });
  const { data: markerPositionFromAddress, error } = useMarkerPositionFromAddress(manualAddress);

  useEffect(() => {
    const setMarkerFromAddress = () => {
      if (!markerPositionFromAddress) return;

      setMarkerPosition(markerPositionFromAddress);
      setDefaultCenter(markerPositionFromAddress);
    };
    setMarkerFromAddress();
  }, [markerPositionFromAddress, setMarkerPosition, manualAddress]);

  return (
    <>
      <GoogleMapComponent
        center={defaultCenter}
        zoom={16}
        mapContainerStyle={{ height: `500px`, width: `100%` }}
        onClick={(g: google.maps.MapMouseEvent) => {
          if (!g.latLng) return;
          setMarkerPosition({ lat: g.latLng.lat(), lng: g.latLng.lng() });
        }}
      >
        {markerPosition && <Marker position={markerPosition} />}
      </GoogleMapComponent>
      {error && <LocateError>{String(error)}</LocateError>}
    </>
  );
};

const LocateError = styled.span`
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
`;
