import { usePresentationCreationQuery } from '../../../../../hooks/usePresentationCreationQuery';
import qs from 'qs';
import { useHistory } from 'react-router-dom';
import { useUpdateSearchCriteria } from '../../../hooks/useUpdateSearchCriteria';
import { SubjectPropertyCriteria } from '../../../../../types';
import { useAddressFromQuery } from '../../../hooks/useAddressFromQuery';

export const useOnAddressContinue = () => {
  const history = useHistory();

  const updateSearchCriteria = useUpdateSearchCriteria();
  const { address, city, state, number, external } = useAddressFromQuery();

  const { type } = usePresentationCreationQuery();

  const onAddressContinue = (addressCriteria: SubjectPropertyCriteria) => {
    updateSearchCriteria(addressCriteria);

    const params = qs.stringify({ type, address, city, state, number, external });

    history.push({
      pathname: '/build/details',
      search: `?${params}`,
    });
  };

  return onAddressContinue;
};
