import noPropertyImage from '../../../assets/images/no-property-image.png';
import { TProperty, TPropertyAPI } from '../../../types';
import { AddressAdapter } from './AddressAdapter';

export class PropertyAdapter {
  static apiToEntity(apiProperty: TPropertyAPI): TProperty {
    return {
      id: apiProperty.id,
      address: AddressAdapter.apiToEntity(apiProperty.full_address),
      excluded: apiProperty.excluded,
      mlsId: Number(apiProperty.mls_id),
      propertyType: apiProperty.property_type,
      size: apiProperty.size,
      sizeDiff: apiProperty.size_diff,
      totalSize: apiProperty.total_size,
      beds: apiProperty.beds,
      totalBaths: apiProperty.total_baths,
      baths: apiProperty.baths,
      daysOnMarket: apiProperty.days_on_market,
      daysToClose: apiProperty.days_to_close,
      price: apiProperty.price,
      pricePerSqft: apiProperty.price_per_sqft,
      salePrice: apiProperty.sale_price,
      salePricePerSqft: apiProperty.sale_price_per_sqft,
      yearBuilt: apiProperty.year_built,
      coordinates: apiProperty.coordinates,
      distance: apiProperty.distance,
      distanceKm: apiProperty.distance_km,
      brokerName: apiProperty.broker_name,
      virtualTour: apiProperty.virtual_tour,
      pool: apiProperty.pool,
      view: apiProperty.view,
      status: apiProperty.status,
      description: apiProperty.description,
      images: apiProperty.images?.length === 0 ? [noPropertyImage] : apiProperty.images,
      listDate: apiProperty.list_date,
      listDateHuman: apiProperty.list_date_human,
      saleDate: apiProperty.sale_date,
      saleDateHuman: apiProperty.sale_date_human,
      hoaFee: apiProperty.hoa_fee,
      hoaFeeValue: apiProperty.hoa_fee_value,
      hoaFeeFreq: apiProperty.hoa_fee_freq,
      disclaimer: apiProperty.disclaimer,
      partialMatch: apiProperty.partial_match,
      partialMatchInfo: apiProperty.partial_match_info,
      singleImage: apiProperty.image,
      levels: apiProperty.levels,
      isFixerUpper: apiProperty.is_fixer_upper,
      isNewConstruction: apiProperty.is_new_construction,
      isAdult55: apiProperty.is_adult55,
      isGreenEnergyStar: apiProperty.is_green_energy_star,
      isHorseProperty: apiProperty.is_horse_property,
      isGolfCourse: apiProperty.is_golf_course,
      isMasterOnMain: apiProperty.is_master_on_main,
      isAirConditioning: apiProperty.is_air_conditioning,
      isWaterFront: apiProperty.is_water_front,
      isWaterView: apiProperty.is_water_view,
      isDeck: apiProperty.is_deck,
      basement: apiProperty.basement,
      garage: apiProperty.garage,
    };
  }
}
