import styled from 'styled-components';
import { ManualMLS } from '../../pages/PresentationCreate/dash/view/pages/Authenticated/PropertySearch/PropertyDetails/ManualMLSList/ManualMLS/ManualMLS';
import { Children, useState } from 'react';
import { TProperty } from '../../types';
import { Droppable } from 'react-beautiful-dnd';
import { ManualComparablesContent } from './components/ManualComparablesContent';

const DroppableWrapper = ({ children }: { children: React.ReactNode }) => (
  <Droppable droppableId={'manual-mls-list'} type={'manual-mls-list'}>
    {(provided, snapshot) => (
      <div
        ref={provided.innerRef}
        {...provided.droppableProps}
        style={{ height: Children.count(children) * 60, width: '100%' }}
      >
        {children}
      </div>
    )}
  </Droppable>
);

const SimpleListWrapper = styled.div`
  width: 100%;
`;

interface Props {
  mlsIds: string[];
  setMlsIds: (ids: string[]) => void;
  subjectId: string;
  withDnD?: boolean;
  onlyActive?: boolean;
  addProperty?: (...properties: TProperty[]) => void;
  removeProperty?: (id: string) => void;
  onUpdate?: () => void;
  activeProperties?: TProperty[];
}

export const OnMLSComparables = ({
  mlsIds,
  setMlsIds,
  subjectId,
  withDnD,
  onlyActive,
  addProperty,
  removeProperty,
  onUpdate,
  activeProperties,
}: Props) => {
  const [errors, setErrors] = useState<{ [key: string]: string }>({});
  const [innerValue, setInnerValue] = useState('');

  const onAdd = (ids: string[], properties: TProperty[]) => {
    const finalIds = [...mlsIds, ...ids.filter(_id => _id.trim().length)];
    setMlsIds(finalIds);
    addProperty && addProperty(...properties);
  };

  const onRemove = (mlsToRemove: string) => {
    const ids = mlsIds.filter(mls => mls !== mlsToRemove);
    setMlsIds(ids);
    removeProperty && removeProperty(mlsToRemove);
  };

  const ListWrapper = withDnD ? DroppableWrapper : SimpleListWrapper;

  return (
    <ManualComparablesContent description='Property is On-MLS: Manually enter MLS IDs to add comparables. Separate multiple IDs with commas.'>
      <>
        {Boolean(mlsIds.length) && (
          <ListWrapper>
            {mlsIds.map((mls, i) => {
              return (
                <ManualMLS
                  key={mls}
                  value={mls}
                  error={errors[mls] ?? ''}
                  onRemove={onRemove}
                  subjectId={subjectId}
                  onlyActive={onlyActive}
                  onUpdate={onUpdate}
                  setErrors={setErrors}
                  style={{ paddingLeft: withDnD ? 20 : 0 }}
                  index={i}
                  withDnD={withDnD}
                  notActive={!activeProperties?.some(p => p?.id === mls)}
                  propertyMlsId={activeProperties?.find(p => p?.id === mls)?.mlsId}
                />
              );
            })}
          </ListWrapper>
        )}
        <ManualMLS
          onAdd={onAdd}
          mlsIds={mlsIds}
          errors={errors}
          subjectId={subjectId}
          setErrors={setErrors}
          innerValue={innerValue}
          setInnerValue={setInnerValue}
        />
      </>
    </ManualComparablesContent>
  );
};
