import React from 'react';

import {
  TDynamicSectionId,
  TPresentationType,
  TSlide,
  TSlideId,
  TTag,
} from '../../../../../../types';
import { PRESENTATION_TYPES } from '../../../../../../constants/PresentationTypes';
import { useUser } from '../../../../../../components/Slide/providers/UserProvider';
import { useOrder } from '../../../../../../providers/providers/OrderProvider';
import { useSlides } from '../../../../../../providers/providers/SlidesProvider';
import { BuyerTourConfigs } from '../../../../../../features/BuyerTour/services/BuyerTourConfigs';
import { useDynamicSlides } from '../../../../../../hooks/dynamicSlides/useDynamicSlides';
import { usePresentationMode } from '../../../../../../providers/providers/PresentationModeProvider';
import { usePresentationType } from '../../../../../../providers/providers/PresentationTypeProvider';
import { PresentationConfigsFactory } from '../services/PresentationSlidesFactory';
import { BuyerTourConfigsUtility } from '../../../../../../features/BuyerTour/services/BuyerTourConfigsUtility';
import { PresentInfoConfigs } from '../../../../../../features/PresentInfo/services/PresentInfoConfigs';
import { useLoadPersonalized } from '../../../../../../data/personalized/useLoadPersonalized';
import { isPopulatedPersonalizedSlide } from '../../../../../../services/isPopulatedPersonalizedSlide';

function filterOutSectionSpecificSlides(
  type: TPresentationType,
  slides: TSlide[],
  section?: TDynamicSectionId,
) {
  if (type !== PRESENTATION_TYPES.BUYER_TOUR || !section) return slides;

  const sectionSpecificSlides = BuyerTourConfigs.getPredefinedSectionSlides();

  return slides.filter(slide => {
    const slidePredefinedSection = BuyerTourConfigsUtility.getSlideSection(
      slide.id as TSlideId,
      sectionSpecificSlides,
    );

    if (!slidePredefinedSection) return true;

    return slidePredefinedSection === section;
  });
}

function filterOutSlidesUsedInOtherSections(
  slides: TSlide[],
  sectionsSlide: Record<TDynamicSectionId, TSlideId[]>,
  section?: TDynamicSectionId,
  personalized = {},
) {
  return slides.filter(s => {
    const currentlyInSection = BuyerTourConfigsUtility.getSlideSection(
      s.id as TSlideId,
      sectionsSlide,
    );

    return !currentlyInSection || currentlyInSection === section;
  });
}

export function useSelectableSectionSlides(tags: TTag[], section?: TDynamicSectionId) {
  const { hash } = useUser();
  const { sectionsSlide } = useOrder();
  const { slides, getSlides } = useSlides();
  const dynamicSlides = useDynamicSlides(hash);
  const { data } = useLoadPersonalized(hash);

  const { presentationType } = usePresentationType();
  const { presentationMode } = usePresentationMode();

  const fullSectionSlides: TSlide[] = React.useMemo(() => {
    const predefined = PresentationConfigsFactory.create(
      presentationType,
      presentationMode,
    ).getSlides();

    const standalone = PresentInfoConfigs.getSlides();

    const personalized = getSlides(['aboutMe', 'testimonials']).reduce(
      (map, slide) => ({ ...map, [slide.id]: slide }),
      {},
    );

    return Object.values({ ...standalone, ...predefined, ...personalized });
  }, [presentationType, presentationMode, getSlides]);

  const dynamicSlidesConfig: TSlide[] = React.useMemo(
    () =>
      dynamicSlides.data
        ?.map(ds => ds.slide)
        .filter(ds => ds.template !== 'pdfPresentationTemplate') ?? [],
    [dynamicSlides.data],
  );

  const combinedPredefinedAndDynamic = React.useMemo(
    function filtredSlides() {
      const predefinedSlides = fullSectionSlides.filter(s => {
        if (s.netProceedsData) {
          return s.netProceedsData.length > 0;
        }

        if (
          ['cma', 'oneSheeter'].includes(presentationMode as string) &&
          s.id === 'closingAndNextSteps'
        )
          return false;

        if (s.id === 'coverSlide' || s.id === 'greetingVideo' || s.id === 'oneSheeter')
          return false;

        if (!isPopulatedPersonalizedSlide(s.id, data)) return false;

        return !s.netProceedsData;
      });

      const reducedPredefined = filterOutSectionSpecificSlides(
        presentationType,
        predefinedSlides,
        section,
      );

      return filterOutSlidesUsedInOtherSections(
        [...reducedPredefined, ...dynamicSlidesConfig],
        sectionsSlide,
        section,
      );
    },
    [
      fullSectionSlides,
      presentationType,
      section,
      dynamicSlidesConfig,
      sectionsSlide,
      presentationMode,
      data,
    ],
  );

  return React.useMemo(() => {
    if (!tags?.length) return combinedPredefinedAndDynamic;

    return combinedPredefinedAndDynamic.filter(
      dsC => !!tags?.find(t => dsC?.tags?.map(dst => dst.id).includes(t.id)),
    );
  }, [tags, combinedPredefinedAndDynamic]);
}
