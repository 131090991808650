import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { format } from 'date-fns';
import styled from 'styled-components';

interface Props {
  date: Date;
  decreaseMonth: () => void;
  increaseMonth: () => void;
}

export const DatePickerHeader = ({ date, decreaseMonth, increaseMonth }: Props) => {
  return (
    <HeaderContainer>
      <HeaderTitle>{format(date, 'MMMM yyyy')}</HeaderTitle>
      <ArrowsContainer>
        <FontAwesomeIcon
          icon={['fas', 'caret-left']}
          onClick={decreaseMonth}
          style={{ cursor: 'pointer' }}
        />
        <FontAwesomeIcon
          icon={['fas', 'caret-right']}
          onClick={increaseMonth}
          style={{ cursor: 'pointer' }}
        />
      </ArrowsContainer>
    </HeaderContainer>
  );
};

const HeaderContainer = styled.div`
  position: relative;
`;

const HeaderTitle = styled.div`
  font-size: 14px;
  font-weight: 500;
`;

const ArrowsContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: absolute;
  top: 2px;
  padding: 0 15px;
  color: ${({ theme }) => theme.colors.v2.primary};
`;
