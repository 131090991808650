import { useDispatch } from 'react-redux';
import { TManualAddress, TSubject } from '../../../../../types';
import { operations } from '../../../../../pages/PresentationCreate/dash/store/search';
import { operations as reportOperations } from '../../../../../pages/PresentationCreate/dash/store/report';
import { Timestamp } from '../../../../../services/Timestamp';

export const useSetInitialConfig = () => {
  const dispatch = useDispatch();

  const setInitialConfig = (address: TManualAddress, property: TSubject) => {
    dispatch(operations.setSearchCriteria({}));

    const searchCriteria = {
      id: property.id,
      propertyType: property.propertyType,
      beds: property.beds,
      baths: property.baths,
      totalBaths: property.totalBaths || property.baths,
      size: property.size,
      totalSize: property.totalSize,
      yearBuilt: property.yearBuilt,
      coordinates: property.coordinates,
      totalSizeSet: false,
      reportCreatedAt: Timestamp.unix(),
    };

    dispatch(operations.setAddress(address));
    dispatch(operations.setSearchCriteria(searchCriteria));
    dispatch(reportOperations.setRebuildData({}));

    return searchCriteria;
  };

  return setInitialConfig;
};
