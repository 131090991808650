import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { TManualAddress } from '../../../../../../types';
import { GOOGLE_MAP_KEY } from '../../../../../../pages/PresentationCreate/dash/config/constants';
import { http } from '../../../../../../services/httpService';

const MARKER_POSITION = 'markerPosition';

export const getMarkerPositionFromAddress = async (
  nonExistentAddress: TManualAddress | null,
): Promise<google.maps.LatLngLiteral | null> => {
  if (!nonExistentAddress) return null;
  const { address, city, number, state } = nonExistentAddress;

  if (!address || !city || !state) return null;

  const fullAddress = `${address} ${number} ${city} ${state}`;
  const url = `https://maps.googleapis.com/maps/api/geocode/json?address=${fullAddress}&key=${GOOGLE_MAP_KEY}`;
  const response = await http.get(url);

  return response?.results?.[0]?.geometry?.location;
};

export function useMarkerPositionFromAddress(
  nonExistentAddress: TManualAddress | null,
): UseQueryResult<google.maps.LatLngLiteral, Error> {
  return useQuery(
    [MARKER_POSITION, nonExistentAddress],
    () => getMarkerPositionFromAddress(nonExistentAddress),
    {
      enabled: Boolean(nonExistentAddress),
      staleTime: Infinity,
      refetchOnWindowFocus: false,
      refetchOnMount: false,
    },
  );
}
