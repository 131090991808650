import { Autocomplete } from '../../../../../components/Common/Autocomplete/Autocomplete';
import { PropertyType } from '../../../../../types';
import { PROPERTY_TYPE_OPTIONS } from '../../../constants/propertyTypeOptions';

interface Props {
  propertyType: PropertyType | null;
  setPropertyType: (propertyType: PropertyType | null) => void;
}
export const PropertyTypeSelector = ({ propertyType, setPropertyType }: Props) => {
  const getPropertyType = async () => [...PROPERTY_TYPE_OPTIONS];

  const onPropertyTypeChange = (pt: string) => {
    const propertyTypeOption = PROPERTY_TYPE_OPTIONS.find(({ label }) => label === pt);

    setPropertyType(propertyTypeOption?.value || null);
  };

  if (!propertyType) return null;

  const selectedLabel =
    PROPERTY_TYPE_OPTIONS.find(({ value }) => value === propertyType)?.label ?? '';

  return (
    <Autocomplete
      label='Property Type'
      value={selectedLabel}
      onChange={onPropertyTypeChange}
      asyncOptionsProvider={getPropertyType}
      suggestions={PROPERTY_TYPE_OPTIONS}
    />
  );
};
