import { useDispatch } from 'react-redux';
import { usePresentationCreationQuery } from '../../../../../hooks/usePresentationCreationQuery';
import { operations } from '../../../../../pages/PresentationCreate/dash/store/search';
import { useSetDefaultResultState } from '../../../../report/hooks/useSetDefaultResultState';
import { usePresentationCreator } from '../../../../../pages/PresentationCreate/hooks/usePresentationCreator';
import { operations as reportOperations } from '../../../../../pages/PresentationCreate/dash/store/report';
import { useUpdatePresentation } from '../../../../../hooks/useUpdatePresentation';
import { PresentationApi } from '../../../../../pages/Presentation/api/PresentationApi';
import { useHistory } from 'react-router-dom';
import { useUpdateSearchCriteria } from '../../../hooks/useUpdateSearchCriteria';
import { SearchCriteria, SubjectPropertyCriteria, TReportBuildPayload } from '../../../../../types';
import { SearchCriteriaDefaultAdapter } from '../../../services/SearchCriteriaDefaultAdapter';
import { useBuildReport } from '../../../hooks/useBuildReport';

export const useOnSkipToPresentation = () => {
  const dispatch = useDispatch();

  const {
    type,
    isRebuild,
    presentation: presentationHash,
    presentationId,
  } = usePresentationCreationQuery();

  const setDefaultResultState = useSetDefaultResultState();
  const { getCreatePresentationPayload } = usePresentationCreator();
  const { mutateAsync: updatePresentation } = useUpdatePresentation(presentationHash);
  const updateSearchCriteria = useUpdateSearchCriteria();
  const history = useHistory();

  const { mutateAsync: createReportBuild } = useBuildReport();
  const buildReportFromBasicInfo = async (criterias: SearchCriteria) => {
    const sc = SearchCriteriaDefaultAdapter.toDefaultSearchCriteria(criterias);

    const reportCriteria = {
      ...criterias,
      presentationType: type,
      rebuild: isRebuild || undefined,
      presentationId: presentationId || undefined,
      excludedIds: [],
    };

    dispatch(operations.setSearchCriteria(sc));

    const reportDetailed = await createReportBuild(reportCriteria);

    dispatch(reportOperations.setReportDetailed(reportDetailed));
    setDefaultResultState(reportDetailed);

    const presentationPayload = await getCreatePresentationPayload();
    await updatePresentation({ ...presentationPayload, id: reportDetailed.presentationId });

    PresentationApi.triggerPdfGeneration(reportDetailed.presentationId || '');

    history.push(`/presentation/${reportDetailed.presentationHash}/edit`);
  };

  const onSkipToPresentation = async (addressCriteria: SubjectPropertyCriteria) => {
    const combinedCriteria = updateSearchCriteria(addressCriteria);
    await buildReportFromBasicInfo(combinedCriteria);
  };

  return { onSkipToPresentation };
};
