import styled from 'styled-components';
import { COMPARABLE_MODAL_MENU_ITEM_ID } from '../../../../../../types';

interface Props {
  children: React.ReactNode;
  title: string;
  titleId: COMPARABLE_MODAL_MENU_ITEM_ID;
}

export const MainSection = ({ children, title, titleId }: Props) => {
  return (
    <Container id={titleId}>
      <Title>{title}</Title>
      {children}
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 20px;
`;

const Title = styled.h4`
  font-size: 18px;
  font-weight: 700;
  color: ${({ theme }) => theme.colors.v2.text.regular};
`;
