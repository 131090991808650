import styled, { useTheme } from 'styled-components';
import { TabButton } from './TabButton';
import { useState } from 'react';
import { OnMLSComparables } from '../../../../../../../../../../features/manual-comparables/OnMLSComparables';
import { OffMLSComparables } from '../../../../../../../../../../features/manual-comparables/OffMLSComparables/OffMLSComparables';

enum TABS {
  ON_MLS = 'onMls',
  OFF_MLS = 'offMls',
}

interface Props {
  mlsIds: string[];
  setMlsIds: (ids: string[]) => void;
  subjectId: string;
}

export const ManualComparableTabs = ({ mlsIds, setMlsIds, subjectId }: Props) => {
  const [activeTab, setActiveTab] = useState<TABS>(TABS.ON_MLS);
  const { colors } = useTheme();

  const tabInactiveStyles = {
    background: colors.v2.gray[100],
    border: `1px solid ${colors.v2.gray[200]}`,
    borderTop: 'none',
  };

  return (
    <Container>
      <TabsWrapper>
        <TabButtonWrapper>
          <TabButton
            text='Add On-MLS Comparable'
            info='Input the MLS Number to automatically pull property details from the MLS database.'
            isActive={activeTab === TABS.ON_MLS}
            onClick={() => setActiveTab(TABS.ON_MLS)}
            inactiveStyles={{
              ...tabInactiveStyles,
              borderLeft: 'none',
            }}
          />
        </TabButtonWrapper>
        <TabButtonWrapper>
          <TabButton
            text='Add Off-MLS Comparable'
            info='Add a property not listed on the MLS by entering its details manually. Use this option to include non-MLS properties in your comparisons.'
            isActive={activeTab === TABS.OFF_MLS}
            onClick={() => setActiveTab(TABS.OFF_MLS)}
            inactiveStyles={{
              ...tabInactiveStyles,
              borderRight: 'none',
            }}
          />
        </TabButtonWrapper>
      </TabsWrapper>
      <ContentWrapper>
        {activeTab === TABS.ON_MLS && (
          <OnMLSComparables mlsIds={mlsIds} setMlsIds={setMlsIds} subjectId={subjectId} />
        )}
        {activeTab === TABS.OFF_MLS && <OffMLSComparables />}
      </ContentWrapper>
    </Container>
  );
};

const Container = styled.div`
  border: ${({ theme }) => `1px solid ${theme.colors.gray[200]}`};
  border-radius: 5px;
  overflow: hidden;
  margin-top: 20px;
`;

const TabsWrapper = styled.div`
  display: flex;
`;
const TabButtonWrapper = styled.div`
  flex: 1;
`;

const ContentWrapper = styled.div`
  padding: 15px;
`;
