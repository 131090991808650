import { PRESENTATION_TYPES } from '../../../../../constants/PresentationTypes';
import { getHasNewCreationFlow } from '../../../../../services/getHasNewCreationFlow';
import { AuthenticatedRoutes, Routes } from '../../../../Router/Routes';

const hasNewCreationFlow = getHasNewCreationFlow();

const winTheRepresentationCard = {
  title: 'WIN THE REPRESENTATION',
  url: `${Routes.presentationNew}?type=${PRESENTATION_TYPES.WIN_THE_REPRESENTATION}&action=create`,
  list: [
    { name: 'Full Analytics', enabled: true },
    { name: 'Custom Content', enabled: true },
    { name: 'Contract Upload', enabled: true },
    { name: 'CMA', enabled: false },
    { name: 'Feedback Capture', enabled: false },
  ],
};

const winTheListingCard = {
  title: 'WIN THE LISTING',
  url: hasNewCreationFlow
    ? `${AuthenticatedRoutes.build}?new=true&type=${PRESENTATION_TYPES.WIN_THE_LISTING}`
    : `/create/search/address?new=true&type=${PRESENTATION_TYPES.WIN_THE_LISTING}`,
  list: [
    { name: 'Full Analytics', enabled: true },
    { name: 'Custom Content', enabled: true },
    { name: 'Contract Upload', enabled: true },
    { name: 'CMA', enabled: true },
    { name: 'Feedback Capture', enabled: true },
  ],
};

const buyerTourCard = {
  title: 'Buyer Tour',
  url: `${Routes.presentationNew}?type=${PRESENTATION_TYPES.BUYER_TOUR}&action=create`,
  list: [
    { name: 'Full Analytics', enabled: true },
    { name: 'Custom Content', enabled: true },
    { name: 'Contract Upload', enabled: false },
    { name: 'CMA', enabled: false },
    { name: 'Feedback Capture', enabled: true },
  ],
};

const winTheOfferCard = {
  title: 'WIN THE OFFER',
  url: hasNewCreationFlow
    ? `${AuthenticatedRoutes.build}?new=true&type=${PRESENTATION_TYPES.WIN_THE_OFFER}`
    : `/create/search/address?new=true&type=${PRESENTATION_TYPES.WIN_THE_OFFER}`,
  list: [
    { name: 'Full Analytics', enabled: true },
    { name: 'Custom Content', enabled: true },
    { name: 'Contract Upload', enabled: true },
    { name: 'CMA', enabled: true },
    { name: 'Feedback Capture', enabled: true },
  ],
};

const generalPresentationCard = {
  title: 'GENERAL PRESENTATION',
  url: `${Routes.presentationNew}?type=${PRESENTATION_TYPES.PRESENT_INFO}&action=create`,
  list: [
    { name: 'Full Analytics', enabled: true },
    { name: 'Custom Content', enabled: true },
    { name: 'Contract Upload', enabled: true },
    { name: 'CMA', enabled: false },
    { name: 'Feedback Capture', enabled: true },
  ],
};

export const presentationCards = {
  [PRESENTATION_TYPES.WIN_THE_LISTING]: winTheListingCard,
  [PRESENTATION_TYPES.WIN_THE_REPRESENTATION]: winTheRepresentationCard,
  [PRESENTATION_TYPES.BUYER_TOUR]: buyerTourCard,
  [PRESENTATION_TYPES.WIN_THE_OFFER]: winTheOfferCard,
  [PRESENTATION_TYPES.PRESENT_INFO]: generalPresentationCard,
};
