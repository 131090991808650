import { useQuery } from '@tanstack/react-query';

import { cmaApi } from '../../../pages/PresentationCreate/dash/communicators/cmaApiCommunicator';
import { PropertyAdapter } from '../services/PropertyAdapter';
import { TSubjectAPI } from '../../../types';
import { SubjectPropertyAdapter } from '../services/SubjectPropertyAdapter';

interface IAddressQueryParams {
  address: string;
  number: string;
  city: string;
  state: string;
  external: boolean;
}

const fetchAddress = async ({ address, city, state, number, external }: IAddressQueryParams) => {
  const apiSearchCriteria: TSubjectAPI = await cmaApi.post('/address/info', {
    address,
    city,
    number,
    state,
    external,
  });

  return !apiSearchCriteria ? null : SubjectPropertyAdapter.apiToEntity(apiSearchCriteria);
};

export const useAddressInfo = ({ address, city, state, number, external }: IAddressQueryParams) => {
  return useQuery(
    ['info', address, city, state, number],
    () => fetchAddress({ address, city, state, number, external }),
    {
      enabled: Boolean(address) && Boolean(city) && Boolean(state),
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      refetchOnReconnect: false,
      staleTime: Infinity,
      retry: false,
    },
  );
};
