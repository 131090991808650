import { PropertyType } from '../../../types';

export const PROPERTY_TYPE_OPTIONS = [
  { label: 'Single Family', value: PropertyType.SINGLE_FAMILY },
  { label: 'Condo', value: PropertyType.CONDO },
  { label: 'Townhouse', value: PropertyType.TOWNHOUSE },
  { label: 'Multi-Unit', value: PropertyType.MULTI_UNIT },
  { label: 'Mobile', value: PropertyType.MOBILE },
  { label: 'Villa', value: PropertyType.VILLA },
];
