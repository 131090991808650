import { cloneDeep } from 'lodash';
import styled from 'styled-components';
import React, { useEffect, useState } from 'react';

import {
  ModalActiveSectionProvider,
  useModalActiveSection,
} from '../providers/ModalActiveSectionProvider';
import { SectionsNavigation } from './SectionsNavigation';
import { useModalActiveSectionSlides } from './useModalActiveSectionSlides';
import { Button } from '../../../../../../components/Common/V2/Button/Button';
import {
  useLevelTagsInSection,
  useTagsInSection,
} from '../../../../../../hooks/dynamicSlides/useTags';
import { PresentationConfigsFactory } from '../services/PresentationSlidesFactory';
import { withProviders } from '../../../../../../hoc/withProviders';
import { useOrder } from '../../../../../../providers/providers/OrderProvider';
import { AddButtonWithModal } from '../components/AddButtonWithModal/AddButtonWithModal';
import { useSlides } from '../../../../../../providers/providers/SlidesProvider';
import { useSections } from '../../../../../../providers/providers/SectionsProvider';
import { SelectableSlidesGrid } from '../components/SelectableSlidesGrid/SelectableSlidesGrid';
import { SlidesTags } from '../../../../../../components/Common/SlidesTags/SlidesTags.component';
import { ModalVisibilityProvider, useModalVisibility } from '../providers/ModalVisibilityProvider';
import {
  TAddSlideBtnStyle,
  TDynamicSectionId,
  TSectionsSlide,
  TSlide,
  TSlideId,
  TTag,
} from '../../../../../../types';
import { usePresentationType } from '../../../../../../providers/providers/PresentationTypeProvider';
import { usePresentationMode } from '../../../../../../providers/providers/PresentationModeProvider';
import { useActiveSection as usePageActiveSection } from '../../../../../../components/Slide/providers/ActiveSectionProvider';
import { pushSlideToTheEndOfTheSlidesOrder } from '../../../../../../services/pushSlideToTheEndOfTheSlideOrder';
import { useLoadPersonalized } from '../../../../../../data/personalized/useLoadPersonalized';
import { useUser } from '../../../../../../components/Slide/providers/UserProvider';
import { isPopulatedPersonalizedSlide } from '../../../../../../services/isPopulatedPersonalizedSlide';

const Container = styled('div')(({ theme }) => ({
  padding: '20px',
  display: 'flex',
  flexDirection: 'column',
  flexShrink: 0,
  background: theme.colors.v2.gradient.background,
}));

interface AddSlideRegularModeProps {
  btnStyle?: TAddSlideBtnStyle;
}

export const AddSlideRegularMode: React.FC<AddSlideRegularModeProps> =
  withProviders<AddSlideRegularModeProps>(
    ModalActiveSectionProvider,
    ModalVisibilityProvider,
  )(({ btnStyle }) => {
    const { presentationType } = usePresentationType();
    const { presentationMode } = usePresentationMode();
    const { hash } = useUser()!;
    const { data } = useLoadPersonalized(hash);

    const { sections } = useSections();
    const { slides: allSlides } = useSlides();

    const { sections: sectionsOrder, sectionsSlide, setSectionsSlideOrder } = useOrder();

    const { closeModal } = useModalVisibility();

    const { activeSection: pageActiveSection } = usePageActiveSection();
    const { modalActiveSection, setModalActiveSection } = useModalActiveSection();

    // const tags = useTagsInSection(modalActiveSection as TDynamicSectionId);
    const tags = useLevelTagsInSection(modalActiveSection as TDynamicSectionId);
    const tagsList = React.useMemo(() => Object.values(tags), [tags]);

    const [selectedTags, setSelectedTags] = useState<TTag[]>([]);

    const [localSectionsSlide, setLocalSectionsSlide] = useState(cloneDeep(sectionsSlide));

    const slides = useModalActiveSectionSlides(selectedTags, localSectionsSlide);

    useEffect(() => {
      setSelectedTags([]);
    }, [modalActiveSection]);

    useEffect(() => {
      setModalActiveSection(pageActiveSection || '');
    }, [pageActiveSection, setModalActiveSection]);

    useEffect(() => {
      if (JSON.stringify(sectionsSlide) === JSON.stringify(localSectionsSlide)) return;

      setLocalSectionsSlide(sectionsSlide);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [sectionsSlide]);

    const onAddSelectedSlide = () => {
      setSectionsSlideOrder(localSectionsSlide);
      closeModal();
    };

    function findSlideSection(sectionSlidesMap: TSectionsSlide, slideId?: TSlideId) {
      if (!slideId || !sectionSlidesMap) return null;

      if (allSlides?.[slideId]?.template) {
        return allSlides[slideId]?.sectionId ?? null;
      }

      let slideSection: TDynamicSectionId | null | string = null;
      Object.keys(sectionSlidesMap).forEach(section => {
        const hasSlide = !!sectionSlidesMap[section].find(slide => slide === slideId);

        if (hasSlide && !slideSection) {
          slideSection = section;
        }
      });

      return slideSection;
    }

    const onSlideClick = (slide: TSlide) => {
      setLocalSectionsSlide(prev => {
        const config = PresentationConfigsFactory.create(presentationType, presentationMode);
        const predefinedSectionsSlides = config.getPredefinedSectionSlides();

        const rootSection = findSlideSection(predefinedSectionsSlides, slide.id as TSlideId);
        if (!rootSection || !Array.isArray(localSectionsSlide[rootSection])) return prev;

        const isPdfTemplate = slide.template === 'pdfPresentationTemplate';
        const existingPdfTemplate = localSectionsSlide[rootSection].find(
          sId => allSlides?.[sId] && allSlides?.[sId]?.template === 'pdfPresentationTemplate',
        );

        let newSlidesOrder = cloneDeep(localSectionsSlide);
        if (isPdfTemplate && existingPdfTemplate !== slide.id) {
          const slidesWithoutPrevPdfTemplate = newSlidesOrder[rootSection].filter(
            s => s !== existingPdfTemplate,
          );
          newSlidesOrder = {
            ...newSlidesOrder,
            [rootSection]: slidesWithoutPrevPdfTemplate,
          };
        }

        const filtered = newSlidesOrder[rootSection].filter(s => s !== slide.id);

        let newOrderWithAddedSlide = [...newSlidesOrder[rootSection], slide.id] as TSlideId[];

        if (rootSection === 'closingAndNextSteps') {
          newOrderWithAddedSlide = pushSlideToTheEndOfTheSlidesOrder(
            newOrderWithAddedSlide,
            'closingAndNextSteps',
          );
        }
        if (rootSection === 'startingSlides') {
          newOrderWithAddedSlide = pushSlideToTheEndOfTheSlidesOrder(
            newOrderWithAddedSlide,
            'coverSlide',
          );
        }

        return filtered.length < newSlidesOrder[rootSection].length
          ? { ...prev, [rootSection]: filtered }
          : ({ ...prev, [rootSection]: newOrderWithAddedSlide } as TSectionsSlide);
      });
    };

    const filteredSlides = slides.filter(s => {
      if (s.netProceedsData) {
        return s.netProceedsData.length > 0;
      }

      if (!isPopulatedPersonalizedSlide(s.id, data)) return false;

      if (presentationType === 'presentInfo' && s.id === 'coverSlide') return true;

      if (s.id === 'coverSlide' || s.id === 'greetingVideo' || s.id === 'oneSheeter') return;

      return !s.netProceedsData;
    });

    const selected = React.useMemo(
      () =>
        Object.values(localSectionsSlide)
          .flatMap(slide => slide)
          .reduce((map, sId) => ({ ...map, [sId]: true }), {}),
      [localSectionsSlide],
    );

    const buttonLabel =
      pageActiveSection === 'startingSlides' && presentationType !== 'presentInfo'
        ? 'Add Company Presentation'
        : 'Add Slide';

    if (!modalActiveSection) return null;

    return (
      <AddButtonWithModal
        buttonLabel={buttonLabel}
        btnStyle={btnStyle}
        onAddClick={onAddSelectedSlide}
      >
        <SectionsNavigation order={sectionsOrder} sections={sections} />

        <SlidesTags
          tagsList={tagsList}
          selectedTags={selectedTags}
          setSelectedTags={setSelectedTags}
        />

        <Container>
          <SelectableSlidesGrid
            selected={selected}
            slides={filteredSlides}
            hasTags={!!tagsList.length}
            onClick={onSlideClick}
          />
        </Container>
      </AddButtonWithModal>
    );
  });
