import { ManualComparableCards } from './ManualComparablesCards/ManualComparableCards';
import { ManualComparableTabs } from './ManualComparableTabs/ManualComparableTabs';

interface Props {
  mlsIds: string[];
  setMlsIds: (ids: string[]) => void;
  subjectId: string;
  isManualView?: boolean;
}

export const ManualComparablesSection = ({ mlsIds, setMlsIds, subjectId, isManualView }: Props) => {
  if (isManualView)
    return <ManualComparableCards mlsIds={mlsIds} setMlsIds={setMlsIds} subjectId={subjectId} />;

  return <ManualComparableTabs mlsIds={mlsIds} setMlsIds={setMlsIds} subjectId={subjectId} />;
};
