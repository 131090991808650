import { getPresentationType } from '../../../services/getPresentationType';
import {
  RawReport,
  ReportFlagsResults,
  TPresentationType,
  TReportBuild,
  TReportBuildPayload,
} from '../../../types';
import { PropertyAdapter } from './PropertyAdapter';
import { SubjectPropertyAdapter } from './SubjectPropertyAdapter';

export class ReportApiAdapter {
  static apiToEntity(apiReportDetailed: TReportBuild): RawReport {
    const flagsWithProperties: Record<string, any> = {};
    const apiFlags = apiReportDetailed.flags;
    for (const key in apiFlags) {
      const value = apiFlags[key as keyof TReportBuild['flags']];
      if (!Array.isArray(value)) continue;
      flagsWithProperties[key] = value.map(property => PropertyAdapter.apiToEntity(property));
    }

    return {
      subject: SubjectPropertyAdapter.apiToEntity(apiReportDetailed.subject),
      properties: {
        active: apiReportDetailed.properties.active.map(PropertyAdapter.apiToEntity),
        comingSoon: apiReportDetailed.properties.coming_soon.map(PropertyAdapter.apiToEntity),
        pending: apiReportDetailed.properties.pending.map(PropertyAdapter.apiToEntity),
        sold: apiReportDetailed.properties.sold.map(PropertyAdapter.apiToEntity),
      },
      graphs: apiReportDetailed.graphs,
      flags: { ...apiFlags, ...(flagsWithProperties as ReportFlagsResults) },
      estimates: apiReportDetailed.estimates,
      compAverages: apiReportDetailed.comp_averages,
      presentationId: String(apiReportDetailed.presentation?.id),
      presentationHash: apiReportDetailed.presentation?.hash,
    };
  }

  static entityToApi(reportDetailed: TReportBuildPayload) {
    return {
      ...reportDetailed,
      presentation_type: getPresentationType(reportDetailed.presentation_type as TPresentationType),
    };
  }
}
