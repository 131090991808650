import { CSSProperties, ReactNode, useState, HTMLAttributes, useEffect } from 'react';
import styled from 'styled-components';
import ReactTooltip from 'react-tooltip';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { DraggableProvided } from 'react-beautiful-dnd';
import { InfoTooltip } from './InfoTooltip';

type Props = HTMLAttributes<HTMLDivElement> & {
  open?: boolean;
  title?: string;
  caret?: boolean;
  children?: ReactNode;
  prefixIcon?: ReactNode | null;
  containerStyle?: CSSProperties;
  onClick?: () => void;
  info?: string;
  provided?: DraggableProvided | null;
  styles?: { root: CSSProperties };
  hideDropdownIcon?: boolean;
};

export function Accordion({
  open = false,
  caret = true,
  title = '',
  prefixIcon = null,
  onClick,
  children,
  containerStyle,
  info,
  provided = null,
  hideDropdownIcon = false,
  styles,
  ...props
}: Props) {
  const [isOpen, setIsOpen] = useState(open);
  const iconName = caret ? 'caret-down' : 'angle-down';

  useEffect(() => {
    setIsOpen(open);
  }, [open]);

  function onToggle() {
    onClick && onClick();
    setIsOpen(prev => !prev);
  }

  return (
    <MainContainer
      ref={provided && provided?.innerRef}
      style={styles?.root ? { ...styles.root, ...containerStyle } : containerStyle}
      {...provided?.draggableProps}
      isOpen={isOpen}
    >
      <Wrapper {...props} {...provided?.dragHandleProps} onClick={onToggle} isOpen={isOpen}>
        <TitleWrapper>
          {prefixIcon ? prefixIcon : null}
          <SectionTitle>{title}</SectionTitle>
          {info && <InfoTooltip info={info} id={title} />}
        </TitleWrapper>
        {hideDropdownIcon ? null : <StyledFontAwesomeIcon isOpen={isOpen} icon={iconName} />}
      </Wrapper>

      {isOpen ? <Dropdown isOpen={isOpen}>{children}</Dropdown> : null}
    </MainContainer>
  );
}

const MainContainer = styled.div<{ isOpen: boolean }>`
  display: flex;
  cursor: pointer;
  border-radius: 5px;
  align-self: stretch;
  flex-direction: column;
  background: ${({ theme }) => theme.colors.v2.background};
  border: 1px solid ${({ theme }) => theme.colors.v2.gray[200]};
`;

const Wrapper = styled.div<{ isOpen: boolean }>`
  display: flex;
  padding: 15px;
  align-items: center;
  justify-self: stretch;
  justify-content: space-between;
  border-radius: ${({ isOpen }) => (isOpen ? '5px 5px 0 0' : '5px')};
  border-bottom: ${({ isOpen, theme }) => (isOpen ? `1px solid ${theme.colors.v2.gray[200]}` : '')};
`;

const TitleWrapper = styled.div`
  display: flex;
  gap: 10px;
  width: fit-content;
  align-items: center;
  justify-content: center;
`;

const SectionTitle = styled.h3`
  display: flex;
  font-size: 14px;
  font-weight: 700;
  font-style: normal;
  text-align: center;
  line-height: normal;
  color: ${({ theme }) => theme.colors.v2.text.regular};
`;

const StyledFontAwesomeIcon = styled(FontAwesomeIcon).withConfig({
  shouldForwardProp: prop => !['isOpen'].includes(prop),
})<{ isOpen: boolean }>`
  transition: 0.5s;
  transform: ${({ isOpen }) => (isOpen ? 'rotate(180deg)' : '')};
`;

const Dropdown = styled.div<{ isOpen?: boolean }>`
  display: flex;
  flex-direction: column;
  transition: 0.5s;
  height: ${({ isOpen }) => (isOpen ? '100%' : '0px')};
`;
