import styled from 'styled-components';

import { Button } from '../../../components/Common/V2/Button/Button';

interface PresentationCreationCTAProps {
  nextText?: string;
  onNext: () => void;
  onPrev?: () => void;
  loading: boolean;
  disabled: boolean;
  onSkipToPresentation?: () => void;
}

export const PresentationCreationCTA = ({
  nextText,
  onNext,
  onPrev,
  loading,
  disabled,
  onSkipToPresentation,
}: PresentationCreationCTAProps) => {
  return (
    <FooterWrapper>
      <ButtonWrapper>
        <Button
          onClick={onNext}
          disabled={disabled || loading}
          isLoading={loading}
          style={{ minWidth: 132 }}
        >
          {nextText}
        </Button>
      </ButtonWrapper>
      <ButtonWrapper>
        {onPrev && (
          <Button onClick={onPrev} disabled={disabled || loading}>
            Previous
          </Button>
        )}
        {onSkipToPresentation && (
          <Button
            variant='secondary'
            onClick={onSkipToPresentation}
            disabled={disabled || loading}
            isLoading={loading}
          >
            Skip To Presentation
          </Button>
        )}
      </ButtonWrapper>
    </FooterWrapper>
  );
};

const FooterWrapper = styled.footer`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  margin-left: auto;
`;
