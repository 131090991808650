import { DatePicker } from '../../../../../../../components/DatePicker/DatePicker';
import { COMPARABLE_MODAL_MENU_SUBITEM_ID } from '../../../../../../../types';
import { SubSection } from '../SubSection';
import { Controller, useFormContext, useWatch } from 'react-hook-form';
import { Input } from '../../../../../../../components/Common/V2/Input';
import styled from 'styled-components';
import { NumberFormatter } from '../../../../../../../pages/PresentationCreate/dash/services/numberFormatter';
import NumberFormat from 'react-number-format';

export const MainFeatures = () => {
  const { control } = useFormContext();

  const selectedStatus = useWatch({ name: 'listingStatus' });

  return (
    <SubSection title='Main Features' titleId={COMPARABLE_MODAL_MENU_SUBITEM_ID.MAIN_FEATURES}>
      <Wrapper>
        <Controller
          name='totalBaths'
          control={control}
          defaultValue={null}
          render={({ field }) => <Input {...field} label='Total Bathroom Count:' type='number' />}
        />
        <Controller
          name='baths'
          control={control}
          defaultValue={null}
          render={({ field }) => <Input {...field} label='Full Bathroom Count:' type='number' />}
        />
        <Controller
          name='totalSize'
          control={control}
          defaultValue={null}
          render={({ field }) => (
            <NumberFormat
              customInput={Input}
              label='Total Lot Size:'
              value={field.value || null}
              onValueChange={({ floatValue }) => {
                field.onChange(floatValue);
              }}
              onBlur={field.onBlur}
              thousandSeparator={true}
              hideErrorMessage
            />
          )}
        />
        <Controller
          name='listDate'
          control={control}
          defaultValue={null}
          render={({ field }) => (
            <DatePicker
              date={field.value}
              setDate={listDate => field.onChange(listDate)}
              label='Date listed:'
              {...field}
            />
          )}
        />
        {selectedStatus?.value === 'sold' && (
          <Controller
            name='saleDate'
            control={control}
            defaultValue={null}
            render={({ field }) => (
              <DatePicker
                date={field.value}
                setDate={saleDate => field.onChange(saleDate)}
                label='Date Sold:'
                {...field}
              />
            )}
          />
        )}
        <Controller
          name='yearBuilt'
          control={control}
          defaultValue={null}
          render={({ field }) => <Input {...field} label='Year Built:' type='number' />}
        />
        <Controller
          name='mlsId'
          control={control}
          defaultValue={null}
          render={({ field }) => <Input {...field} label='MLS ID:' />}
        />
      </Wrapper>
    </SubSection>
  );
};

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(4, auto);
  gap: 10px;
`;
