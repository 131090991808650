import { useDispatch, useSelector } from 'react-redux';
import { operations } from '../../../pages/PresentationCreate/dash/store/search';

export const useUpdateSearchCriteria = () => {
  const searchCriteria = useSelector(({ search }: any) => search.searchCriteria);
  const dispatch = useDispatch();
  const updateCriteria = (updatedCriteria: any) => {
    const combinedCriteria = {
      ...searchCriteria,
      ...updatedCriteria,
    };
    combinedCriteria.mapMode =
      typeof combinedCriteria.mapCoordinates == 'string' ? 'polygon' : 'marker';
    dispatch(operations.setSearchCriteria(combinedCriteria));
    return combinedCriteria;
  };
  return updateCriteria;
};
