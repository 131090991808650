import { Dispatch, SetStateAction, useEffect, useMemo, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import qs from 'qs';

import { TManualAddress, TProperty, TSubject } from '../../../../types';
import { useUser } from '../../../../components/Slide/providers/UserProvider';
import { usePresentationCreationQuery } from '../../../../hooks/usePresentationCreationQuery';
import { Input } from '../../../../components/Common/V2/Input';
import { Select } from '../../../../components/Common';
import { useAddressInfo } from '../../hooks/useAddressInfo';
import { parseQueryParams } from '../../services/parseQueryParams';
import { Autocomplete } from '../../../../components/Common/Autocomplete/Autocomplete';
import { PresentationCreationCTA } from '../../components/PresentationCreationCTA';
import { useSetInitialConfig } from './hooks/useSetInitialConfig';
import { useAddressSearch } from '../../hooks/useAddressSearch';
import { states } from '../../../../constants/states';

type TAddressOption = {
  deliveryLine: string;
  city: string;
  external: boolean;
  state: string;
  value: string;
};

type TErrors = {
  [key: string]: string | undefined;
};

interface IPropertyForm {
  disableFooter: boolean;
  onNonExistentAddress: (address: TManualAddress) => void;
  clearAddressForm: boolean;
  setClearAddressForm: (value: boolean) => void;
  setOverridePropertyStep: Dispatch<SetStateAction<boolean>>;
}

const statesList = states.map(({ name, abbreviation }) => {
  return { value: abbreviation, label: name };
});

export const PropertyAddressForm = ({
  disableFooter,
  onNonExistentAddress,
  clearAddressForm,
  setClearAddressForm,
  setOverridePropertyStep,
}: IPropertyForm) => {
  const [errors, setErrors] = useState<TErrors>({});

  const [address, setAddress] = useState<string>('');
  const [external, setExternal] = useState(false);
  const [addressOptions, setAddressOptions] = useState<TAddressOption[]>([]);

  const [number, setNumber] = useState<string>('');
  const [city, setCity] = useState<string>('');
  const [state, setState] = useState<any>('');

  const location = useLocation();
  const history = useHistory();

  const { isCanadaUser } = useUser();

  const { type } = usePresentationCreationQuery();
  const { mutateAsync: addressSearch } = useAddressSearch();

  const {
    data: searchCriteria,
    isLoading,
    isError,
  } = useAddressInfo({
    address,
    city,
    state: state?.value,
    number,
    external,
  });
  const setInitialConfig = useSetInitialConfig();

  const loading = useMemo(() => {
    return isLoading && Boolean(address) && Boolean(city) && Boolean(state);
  }, [address, city, isLoading, state]);

  const onContinue = (address: TManualAddress, property: TSubject) => {
    setInitialConfig(address, property);
    setOverridePropertyStep(true);
  };

  const getAddressOptions = async (address: any) => {
    if (isCanadaUser) {
      return [];
    }
    const addresses = await addressSearch(address);

    const autocompleteAddresses = addresses.map((address: any) => ({
      ...address,
      label: address.fullAddress,
      value: address.fullAddress,
    }));

    setAddressOptions(autocompleteAddresses);
    setErrors({});
  };

  const onAddressChange = (addressQuery: string) => {
    setAddress(addressQuery);

    const addressOption = addressOptions.find(({ value }) => value === addressQuery);
    if (!addressOption) {
      return;
    }

    setAddress(addressOption.deliveryLine);
    setCity(addressOption.city);
    setExternal(addressOption.external);
    setState(statesList.find(({ value }) => value === addressOption.state));
  };

  useEffect(() => {
    const queryParams = parseQueryParams(location.search);

    const updateStateFromQueryParams = (queryParams: qs.ParsedQs) => {
      if (address || city || state || number) return;

      if (queryParams.address) setAddress(queryParams.address as string);
      if (queryParams.city) setCity(queryParams.city as string);
      if (queryParams.state) setState(statesList.find(({ value }) => value === queryParams.state));
      if (queryParams.number) setNumber(queryParams.number as string);
      if (queryParams.external) setExternal(queryParams.external === 'true' ? true : false);
    };

    if (queryParams.address || queryParams.city || queryParams.state || queryParams.number) {
      updateStateFromQueryParams(queryParams);

      if (searchCriteria) {
        onContinue({ address, number, city, state }, searchCriteria);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (clearAddressForm) {
      setAddress('');
      setCity('');
      setState('');
      setNumber('');
      setExternal(false);
      setErrors({});
      setClearAddressForm(false);
    }
  }, [clearAddressForm, setClearAddressForm]);

  const onSubmit = async () => {
    let sc = null;

    sc = isCanadaUser ? null : searchCriteria;

    if (!sc?.coordinates?.latitude || isError) {
      onNonExistentAddress({
        address,
        number,
        city,
        state,
        size: sc?.size,
        beds: sc?.beds,
      });
      return;
    }

    const queryParams = qs.stringify({
      address,
      city,
      state: state.value,
      number,
      type: type,
      step: 'info',
      external: external ? 'true' : 'false',
    });

    history.push({
      pathname: location.pathname,
      search: `?${queryParams}`,
    });
    onContinue({ address, number, city, state }, sc);
  };

  const onChange = (errorField: string, setFieldValue: (value: string) => void) => (value: any) => {
    delete errors[errorField];
    setErrors({ ...errors });
    setFieldValue(value);
  };

  return (
    <div>
      <Title>WHAT IS THE ADDRESS?</Title>

      <SectionWrapper>
        <GridWrapper>
          <Autocomplete
            label={isCanadaUser ? 'Address (Include City Name):' : 'Address:'}
            value={address}
            error={errors?.address}
            onChange={onChange('address', onAddressChange)}
            asyncOptionsProvider={getAddressOptions}
            required={isCanadaUser}
            suggestions={addressOptions}
          />
          <Input
            label='Apt or Suite #:'
            value={number}
            inputWrapperStyle={{ width: '100%' }}
            inputStyle={{ width: '100%' }}
            onChange={e => onChange('apartment_number', setNumber)(e.target.value)}
          />
        </GridWrapper>

        <GridWrapper style={isCanadaUser ? { gridTemplateColumns: '1fr' } : {}}>
          {!isCanadaUser && (
            <Input
              label='City'
              value={city}
              error={errors.city}
              onChange={e => onChange('city', setCity)(e.target.value)}
            />
          )}
          {!isCanadaUser && (
            <div style={{ position: 'relative' }}>
              <Select
                defaultValue={state && [state]}
                options={statesList}
                onChange={onChange('state', setState)}
                placeholder='State'
              />
              {errors.state && <ErrorText>{errors.state}</ErrorText>}
            </div>
          )}
          {isCanadaUser && (
            <Input
              label='Province'
              value={city}
              error={errors.city}
              onChange={e => onChange('city', setCity)(e.target.value)}
            />
          )}
        </GridWrapper>
      </SectionWrapper>

      {!disableFooter && (
        <div style={{ marginTop: 20 }}>
          <PresentationCreationCTA
            nextText='Next'
            onNext={onSubmit}
            loading={loading && Boolean(address) && Boolean(city) && Boolean(state)}
            disabled={loading && Boolean(address) && Boolean(city) && Boolean(state)}
          />
        </div>
      )}
    </div>
  );
};

const GridWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
    gap: 15px;
  }
`;

const Title = styled.h3`
  font-size: 14px;
  line-height: normal;
  font-weight: 700;
  font-style: normal;
  margin-bottom: 10px;
  color: ${props => props.theme.colors.v2.gray[400]};
`;

const SectionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
`;

const ErrorText = styled.span`
  position: absolute;
  bottom: -14px;
  left: 0px;
  font-size: 11px;
  color: ${props => props.theme.colors.v2.status.error};
`;
