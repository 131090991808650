import styled, { useTheme } from 'styled-components';
import CreatableSelect from 'react-select/creatable';
import React, { Dispatch, SetStateAction } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ReactSelect, { ValueType, components } from 'react-select';

import { customStyles, CustomSelectStyles } from './Select.styles';
import { Icon } from '../Icon/Icon.component';

export interface SelectOption {
  value?: string | number | any;
  label: string;
  options?: { label: string; value: string | number }[];
}

export type SelectOptionChangeEventValue = ValueType<SelectOption, any>;

interface Props {
  selectedOption?: SelectOption;
  options: SelectOption[];
  disabled?: boolean;
  styles?: CustomSelectStyles;
  placeholder?: string;
  onChange: (selectedOption: SelectOptionChangeEventValue | SelectOptionChangeEventValue[]) => void;
  setIsMenuOpen?: Dispatch<SetStateAction<boolean>>;
  useDataFonts?: boolean;
  menuPlacementTop?: boolean;
  isMulti?: boolean;
  createable?: boolean;
  defaultValue?: SelectOption[];
  menuPlacement?: string;
  menuPortalTarget?: HTMLElement;
  label?: string;
  required?: boolean;
  error?: string;
}

export const Select: React.FC<Props> = ({
  options,
  selectedOption,
  disabled,
  styles = {},
  useDataFonts,
  placeholder,
  createable,
  onChange,
  setIsMenuOpen,
  menuPlacementTop,
  isMulti,
  defaultValue,
  menuPlacement,
  menuPortalTarget,
  label,
  required,
  error,
}) => {
  const { colors } = useTheme();

  const Component = createable ? CreatableSelect : ReactSelect;

  const hasError = Boolean(error);

  return (
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    <Component
      label={label}
      required={required}
      defaultValue={defaultValue}
      value={selectedOption ? selectedOption : defaultValue}
      styles={customStyles(colors, styles, useDataFonts, hasError)}
      isDisabled={disabled}
      isSearchable={isMulti || false}
      placeholder={placeholder}
      isClearable={false}
      isMulti={isMulti}
      closeMenuOnSelect={!isMulti}
      theme={(theme: any) => ({
        ...theme,
        borderRadius: 5,
        colors: {
          ...theme.colors,
          primary25: colors.gray[100],
          primary: colors.primary.main,
        },
      })}
      options={options}
      components={{
        SingleValue: Label,
        IndicatorSeparator: () => null,
        DropdownIndicator,
        Placeholder: Placeholder,
      }}
      onChange={onChange}
      onMenuOpen={() => {
        if (setIsMenuOpen) setIsMenuOpen(true);
      }}
      onMenuClose={() => {
        if (setIsMenuOpen) setIsMenuOpen(false);
      }}
      menuPlacement={menuPlacementTop ? 'top' : menuPlacement ? menuPlacement : 'bottom'}
      menuPortalTarget={menuPortalTarget}
      hasError={hasError}
    />
  );
};

const DropdownIndicator = (props: any) => {
  return (
    <components.DropdownIndicator {...props}>
      <FontAwesomeIcon icon={['fas', 'chevron-down']} />
    </components.DropdownIndicator>
  );
};

const Label = (props: any) => {
  const { colors } = useTheme();

  const hasLabelOrRequired = props.selectProps.label || props.selectProps.required;
  const fillColor = colors.v2.status.error;

  return (
    <components.SingleValue {...props}>
      <StyledLabelWrapper hasLabelOrRequired={hasLabelOrRequired}>
        <StyledLabel>
          {props.selectProps.required && <Icon iconName='bt_required' fill={fillColor} />}
          {props.selectProps.label}
        </StyledLabel>
        {props.data.label}
      </StyledLabelWrapper>
    </components.SingleValue>
  );
};

const Placeholder = (props: any) => {
  const { colors } = useTheme();

  const hasLabelOrRequired = props.selectProps.label || props.selectProps.required;
  const hasError = props.selectProps.hasError;
  const fillColor = colors.v2.status.error;

  return (
    <components.Placeholder {...props}>
      <StyledLabelWrapper hasLabelOrRequired={hasLabelOrRequired}>
        <StyledLabel hasError={hasError}>
          {props.selectProps.required && <Icon iconName='bt_required' fill={fillColor} />}
          {props.selectProps.label}
        </StyledLabel>
      </StyledLabelWrapper>
    </components.Placeholder>
  );
};

const StyledLabelWrapper = styled.div<{ hasLabelOrRequired: boolean }>`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  ${props =>
    props.hasLabelOrRequired &&
    `
  display: flex;
  align-items: center;
  gap: 10px;
  `}
`;

const StyledLabel = styled.label<{ disabled?: boolean; hasError?: boolean }>`
  color: ${props =>
    props.hasError
      ? props.theme.colors.v2.status.error
      : props.disabled
        ? props.theme.colors.v2.gray[200]
        : props.theme.colors.v2.gray[400]};
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  white-space: nowrap;
  display: flex;
  gap: 5px;
`;
