import styled from 'styled-components';
import { Input } from '../../../../../components/Common/V2/Input';
import NumberFormat from 'react-number-format';
import { useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { PropertyTypeSelector } from './PropertyTypeSelector';
import { PropertyType } from '../../../../../types';
import { PresentationCreationCTA } from '../../../components/PresentationCreationCTA';
import { FEATURES, useHasFeatureFlag } from '../../../../../hooks/useFeatureFlags';
import { useUser } from '../../../../../components/Slide/providers/UserProvider';
import { useOnAddressContinue } from '../hooks/useOnAddressContinue';
import { useOnSkipToPresentation } from '../hooks/useOnSkipToPresentation';
import { toast } from 'react-toastify';

interface Props {
  onPrevious: () => void;
}

export const OverrideForm = ({ onPrevious }: Props) => {
  const searchCriteria = useSelector(({ search }: { search: any }) => search.searchCriteria);
  const [propertyType, setPropertyType] = useState<PropertyType | null>(null);
  const [error, setError] = useState<string | null>(null);
  const [beds, setBeds] = useState(0);
  const [size, setSize] = useState(0);
  const [yearBuilt, setYearBuilt] = useState(0);
  const [baths, setBaths] = useState(0);
  const hasBathCountFF = useHasFeatureFlag(FEATURES.CpSubjectBathCount);
  const [isLoading, setIsLoading] = useState(false);

  const onAddressContinue = useOnAddressContinue();

  const { onSkipToPresentation } = useOnSkipToPresentation();

  const { isCanadaUser } = useUser();

  useEffect(() => {
    setPropertyType(searchCriteria.propertyType || '');
    setBeds(isNaN(parseInt(searchCriteria.beds)) ? null : searchCriteria.beds);
    setSize(searchCriteria.size || 0);
    setYearBuilt(searchCriteria.yearBuilt || 0);
    setBaths(searchCriteria.baths || 0);
  }, [
    searchCriteria.beds,
    searchCriteria.propertyType,
    searchCriteria.size,
    searchCriteria.yearBuilt,
    searchCriteria.baths,
    searchCriteria,
  ]);
  const setBedsEnsureInt = (beds: string) => {
    setBeds(parseInt(beds));
  };

  const setBathsEnsureInt = (baths: string) => {
    setBaths(parseInt(baths));
  };

  const setSizeEnsureInt = (size?: number) => {
    if (!size) return;
    setSize(size);
  };

  const setYearBuiltEnsureInt = (yearBuilt: string) => {
    setYearBuilt(parseInt(yearBuilt));
  };

  const validateForm = () => {
    setError(null);

    if (isNaN(parseInt(String(beds))) || !size) {
      setError('Please enter all data.');
      return;
    }
  };

  const skipToPresentation = async () => {
    validateForm();

    try {
      setIsLoading(true);
      await onSkipToPresentation({
        beds,
        propertyType: propertyType || '',
        size,
        yearBuilt,
        totalBaths: baths,
      });
      setIsLoading(false);
    } catch (e) {
      console.error(e);
      toast(
        'We had trouble creating your presentation. Please try again. If the issue persists, please contact our support team.',
        {
          type: 'error',
          position: 'bottom-center',
        },
      );
      setIsLoading(false);
    }
  };

  const onNext = async () => {
    validateForm();

    onAddressContinue({
      beds,
      propertyType: propertyType || '',
      size,
      yearBuilt,
      totalBaths: baths,
    });
  };

  const disableNext =
    beds < 0 || size < 0 || yearBuilt < 0 || isNaN(parseInt(String(beds))) || !size || baths < 0;
  const hasNegativeValues = beds < 0 || size < 0 || yearBuilt < 0 || baths < 0;
  return (
    <>
      <SectionWrapper>
        <GridWrapper hasBathCount={hasBathCountFF} isCanadaUser={isCanadaUser}>
          <PropertyTypeSelector propertyType={propertyType} setPropertyType={setPropertyType} />

          <Input
            label='Bedroom Count'
            type='number'
            value={String(beds)}
            placeholder='Please enter'
            onChange={e => setBedsEnsureInt(e.target.value)}
          />
          {hasBathCountFF && (
            <Input
              label='Total Bath Count:'
              type='number'
              value={String(baths)}
              placeholder='Please enter'
              onChange={e => setBathsEnsureInt(e.target.value)}
            />
          )}
        </GridWrapper>

        <GridWrapper>
          <NumberFormat
            customInput={Input}
            label='Living Area Size (sqft)'
            value={size || undefined}
            placeholder='Please enter'
            onValueChange={({ floatValue }) => setSizeEnsureInt(floatValue)}
            thousandSeparator={true}
          />

          <Input
            label='Year Built'
            type='number'
            value={String(yearBuilt)}
            onChange={e => setYearBuiltEnsureInt(e.target.value)}
          />
        </GridWrapper>
      </SectionWrapper>
      <ErrorText>{hasNegativeValues ? 'Please enter positive numbers' : error}</ErrorText>
      <FooterWrapper>
        <PresentationCreationCTA
          nextText='Next: Comparable Details'
          onPrev={onPrevious}
          onNext={onNext}
          loading={isLoading}
          disabled={disableNext}
          onSkipToPresentation={skipToPresentation}
        />
      </FooterWrapper>
    </>
  );
};

const GridWrapper = styled.div<{ hasBathCount?: boolean; isCanadaUser?: boolean }>`
  display: grid;
  grid-template-columns: ${({ hasBathCount, isCanadaUser }) =>
    hasBathCount && !isCanadaUser ? '1fr 1fr 1fr' : '1fr 1fr'};
  gap: 10px;

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
    gap: 15px;
  }
`;

const SectionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
`;

const ErrorText = styled.span`
  color: ${({ theme }) => theme.colors.error};
  font-size: 12px;
`;

const FooterWrapper = styled.div`
  margin-top: 20px;
`;
