import { PRESENTATION_CREATION_SEARCH_PARAMS } from '../types';

interface Args {
  type: string;
  presentationHash?: string;
  presentationId?: string;
  action?: string;
}

export const buildPresentationCreationSearchParams = ({
  type,
  presentationHash,
  presentationId,
  action,
}: Args) => {
  const params = new URLSearchParams();

  if (type) params.append(PRESENTATION_CREATION_SEARCH_PARAMS.TYPE, type);

  if (presentationHash)
    params.append(PRESENTATION_CREATION_SEARCH_PARAMS.PRESENTATION, presentationHash);
  if (presentationId)
    params.append(PRESENTATION_CREATION_SEARCH_PARAMS.PRESENTATION_ID, presentationId);
  if (action) params.append(PRESENTATION_CREATION_SEARCH_PARAMS.ACTION, action);

  return params;
};
