import { PRESENTATION_CREATION_SEARCH_PARAMS, TPresentationType } from '../types';
import { useUrlSearchParam } from './useQueryParams';

type TPresentationCreationQuery = {
  type: TPresentationType;
  isRebuild: boolean;
  presentation?: string;
  presentationId?: string;
  action?: string;
};

export function usePresentationCreationQuery(): TPresentationCreationQuery {
  const type = useUrlSearchParam(PRESENTATION_CREATION_SEARCH_PARAMS.TYPE) as TPresentationType;
  const action = useUrlSearchParam(PRESENTATION_CREATION_SEARCH_PARAMS.ACTION) ?? '';
  const presentation = useUrlSearchParam(PRESENTATION_CREATION_SEARCH_PARAMS.PRESENTATION) ?? '';
  const presentationId =
    useUrlSearchParam(PRESENTATION_CREATION_SEARCH_PARAMS.PRESENTATION_ID) ?? '';

  return {
    type,
    isRebuild: action === 'rebuild',
    presentation,
    presentationId,
    action,
  };
}
