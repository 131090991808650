import { useRef } from 'react';
import styled from 'styled-components';
import { COMPARABLE_MODAL_MENU_ITEM_ID, COMPARABLE_MODAL_MENU_SUBITEM_ID } from '../../../../types';
import { sideMenuItems } from './ComparablesModalContent/sideMenuItems';

interface Props {
  activeMainItemId: COMPARABLE_MODAL_MENU_ITEM_ID;
  setActiveMainItemId: (id: COMPARABLE_MODAL_MENU_ITEM_ID) => void;
  activeSubItemId: COMPARABLE_MODAL_MENU_SUBITEM_ID | null;
  setActiveSubItemId: (id: COMPARABLE_MODAL_MENU_SUBITEM_ID | null) => void;
}

export const SideMenu = ({
  activeMainItemId,
  setActiveMainItemId,
  activeSubItemId,
  setActiveSubItemId,
}: Props) => {
  const menuRef = useRef<HTMLDivElement>(null);

  const onMainItemClick = (id: COMPARABLE_MODAL_MENU_ITEM_ID) => {
    if (id === COMPARABLE_MODAL_MENU_ITEM_ID.OPTIONAL_FIELDS) {
      setActiveSubItemId(COMPARABLE_MODAL_MENU_SUBITEM_ID.MAIN_FEATURES);
    }

    if (id === COMPARABLE_MODAL_MENU_ITEM_ID.REQUIRED_FIELDS) {
      setActiveSubItemId(null);
    }

    const anchor = document.querySelector(`#${id}`);
    anchor?.scrollIntoView({ behavior: 'smooth', block: 'start' });

    setActiveMainItemId(id);
  };

  const onSubItemClick = (id: COMPARABLE_MODAL_MENU_SUBITEM_ID) => {
    setActiveSubItemId(id);
    const anchor = document.querySelector(`#${id}`);
    anchor?.scrollIntoView({ behavior: 'smooth', block: 'start' });
  };

  return (
    <Container ref={menuRef}>
      {sideMenuItems.map(item => (
        <MainItemContainer key={item.id}>
          <MenuItem
            isActive={activeMainItemId === item.id}
            onClick={() => onMainItemClick(item.id)}
          >
            {item.title}
          </MenuItem>
          {activeMainItemId === COMPARABLE_MODAL_MENU_ITEM_ID.OPTIONAL_FIELDS &&
            item.subItems?.length && (
              <SubItemsContainer>
                {item.subItems?.map(subItem => (
                  <MenuItem
                    isActive={activeSubItemId === subItem.id}
                    key={subItem.id}
                    onClick={() => onSubItemClick(subItem.id)}
                    isSubItem
                  >
                    {subItem.title}
                  </MenuItem>
                ))}
              </SubItemsContainer>
            )}
        </MainItemContainer>
      ))}
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 28%;
  padding: 20px;
  border-right: 1px solid ${({ theme }) => theme.colors.v2.gray[100]};
`;

const MainItemContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const MenuItem = styled.div<{ isActive: boolean; isSubItem?: boolean }>`
  height: 36px;
  padding: ${({ isSubItem }) => (isSubItem ? '0 8px 0 28px' : '0 8px')};
  width: 100%;
  display: flex;
  align-items: center;
  border-radius: 5px;
  cursor: pointer;
  background: ${({ theme, isActive, isSubItem }) => {
    if (!isActive) return 'transparent';
    if (isSubItem) return theme.colors.v2.gray[100];
    return theme.colors.v2.primary;
  }};
  font-size: 14px;
  font-weight: 500;
  color: ${({ isActive, theme, isSubItem }) => {
    if (isSubItem) return theme.colors.v2.text.regular;
    if (isActive) return theme.colors.v2.text.contrastText;
    return theme.colors.v2.text.regular;
  }};
`;

const SubItemsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding-top: 10px;
`;
