import styled from 'styled-components';
import { InfoTooltip } from '../../../../../../../../../../components/Common/V2/InfoTooltip';

interface Props {
  children: React.ReactNode;
  title: string;
  info: string;
}

export const ComparableCard = ({ children, title, info }: Props) => {
  return (
    <Card>
      <TitleWrapper>
        <Title>{title}</Title>
        <InfoTooltip info={info} id={title} title={title} />
      </TitleWrapper>
      {children}
    </Card>
  );
};

const Card = styled.div`
  border-radius: 5px;
  border: ${({ theme }) => `1px solid ${theme.colors.gray[200]}`};
  padding: 15px;
`;

const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 5px;
`;

const Title = styled.div`
  font-size: 14px;
  font-weight: 700;
  color: ${({ theme }) => theme.colors.v2.text.regular};
  text-transform: uppercase;
`;
