import styled from 'styled-components';
import {
  COMPARABLE_MODAL_MENU_ITEM_ID,
  COMPARABLE_MODAL_MENU_SUBITEM_ID,
} from '../../../../../../../types';
import { MainSection } from '../MainSection';
import { SubSection } from '../SubSection';
import { MainFeatures } from './MainFeatures';

export const OptionalFields = () => {
  return (
    <MainSection title='Optional Fields' titleId={COMPARABLE_MODAL_MENU_ITEM_ID.OPTIONAL_FIELDS}>
      <SubSectionsContainer>
        <MainFeatures />
        <SubSection
          title='Interior Features'
          titleId={COMPARABLE_MODAL_MENU_SUBITEM_ID.INTERIOR_FEATURES}
        >
          <HeightWrapper>Interior features placeholder</HeightWrapper>
        </SubSection>
        <SubSection
          title='Exterior Features'
          titleId={COMPARABLE_MODAL_MENU_SUBITEM_ID.EXTERIOR_FEATURES}
        >
          <HeightWrapper>Exterior features placeholder</HeightWrapper>
        </SubSection>
        <SubSection
          title='Other Features'
          titleId={COMPARABLE_MODAL_MENU_SUBITEM_ID.OTHER_FEATURES}
        >
          <HeightWrapper>Other features placeholder</HeightWrapper>
        </SubSection>
        <SubSection title='Description' titleId={COMPARABLE_MODAL_MENU_SUBITEM_ID.DESCRIPTION}>
          <HeightWrapper>Description placeholder</HeightWrapper>
        </SubSection>
        <SubSection title='Virtual Tour' titleId={COMPARABLE_MODAL_MENU_SUBITEM_ID.VIRTUAL_TOUR}>
          <HeightWrapper>Virtual tour placeholder</HeightWrapper>
        </SubSection>
        <SubSection title='Upload Image' titleId={COMPARABLE_MODAL_MENU_SUBITEM_ID.UPLOAD_IMAGE}>
          <HeightWrapper> Upload image placeholder</HeightWrapper>
        </SubSection>
      </SubSectionsContainer>
    </MainSection>
  );
};

// TODO: remove after content is added
const HeightWrapper = styled.div`
  height: 500px;
`;

const SubSectionsContainer = styled.div`
  display: flex;
  flex-direction: column;
`;
