import styled from 'styled-components';

interface Props {
  description: string;
  children?: React.ReactNode;
}

export const ManualComparablesContent = ({ description, children }: Props) => {
  return (
    <Container>
      <Description>{description}</Description>
      {children}
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const Description = styled.p`
  font-size: 12px;
  font-weight: 500;
  color: ${({ theme }) => theme.colors.gray[400]};
  margin-bottom: 10px;
`;
