import { useMutation } from '@tanstack/react-query';
import { cmaApi } from '../../../../../../pages/PresentationCreate/dash/communicators/cmaApiCommunicator';
import { TAddressCreationFields, TSubject } from '../../../../../../types';

const createAddress = async ({
  fields,
  markerPosition,
}: {
  fields: TAddressCreationFields;
  markerPosition: google.maps.LatLngLiteral | null;
}): Promise<TSubject> => {
  const { address, number, state, city, beds, size, yearBuilt, baths } = fields;

  const response = await cmaApi.post('/address/create', {
    address,
    number,
    city,
    state,
    beds,
    size,
    // total_size: totalSize,
    lat: markerPosition?.lat,
    lng: markerPosition?.lng,
    year_built: yearBuilt,
    baths,
  });

  return {
    id: response.id,
    propertyType: response.property_type,
    status: response.sale_type,
    beds: response.beds,
    baths: response.total_baths,
    size: response.size,
    totalSize: response.total_size,
    yearBuilt: response.year_built,
    coordinates: {
      latitude: response.coordinates.latitude,
      longitude: response.coordinates.longitude,
    },
  } as TSubject;
};
export const useCreateAddress = () => {
  return useMutation(createAddress, {
    onError: err => {
      console.error('Error while creating address', err);
    },
  });
};
