import { useMutation } from '@tanstack/react-query';
import { cmaApi } from '../../../pages/PresentationCreate/dash/communicators/cmaApiCommunicator';
import { AddressAdapter } from '../services/AddressAdapter';

const search = async (addressQuery: string) => {
  const apiAddresses = await cmaApi.post('/address/search', {
    address: addressQuery,
  });

  return apiAddresses.map(AddressAdapter.apiToEntity);
};

export const useAddressSearch = () => {
  return useMutation(search, {
    onError: err => {
      console.error('Error while searching address', err);
    },
  });
};
