import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useAtomValue, useSetAtom } from 'jotai';
import { toast } from 'react-toastify';
import qs from 'qs';
import { useDispatch, useSelector } from 'react-redux';

import { PropertyDetails } from '../../../pages/PresentationCreate/dash/view/pages/Authenticated/PropertySearch/PropertyDetails/PropertyDetails';
import { operations } from '../../../pages/PresentationCreate/dash/store/search';
import { operations as reportOperations } from '../../../pages/PresentationCreate/dash/store/report';
import { criteriaToApiMapper } from '../../../pages/PresentationCreate/dash/view/pages/Authenticated/PropertySearch/SearchApiMapper';
import { ReportDetailedCommunicator } from '../../../pages/PresentationCreate/dash/communicators/ReportDetailed/ReportDetailedCommunicator';
import { usePresentationCreationQuery } from '../../../hooks/usePresentationCreationQuery';
import { useSetSearchParameter } from '../../../hooks/useSetSearchParameter';
import { useSetDefaultResultState } from '../../report/hooks/useSetDefaultResultState';
import { useResultRebuildStateSetter } from '../../../pages/PresentationCreate/dash/view/pages/Authenticated/CompResults/useResultRebuildStateSetter';
import { usePresentationCreator } from '../../../pages/PresentationCreate/hooks/usePresentationCreator';
import { useUpdatePresentation } from '../../../hooks/useUpdatePresentation';
import { useUpdatePresentationWithoutSaving } from '../../../hooks/useUpdatePresentationWithoutSaving';
import { PresentationApi } from '../../../pages/Presentation/api/PresentationApi';
import { useAddressFromQuery } from '../hooks/useAddressFromQuery';
import { manualAddressAtom } from '../address-selection-step/state/manualAddressAtom';
import { AuthenticatedRoutes } from '../../../pages/Router/Routes';
import { PresentationCreationStepperLayout } from '../layout/PresentationCreationStepperLayout';
import { StepsCard } from '../components/StepsCard';
import { URLS } from '../../../pages/PresentationCreate/dash/view/constants/URLS';
import { buildPresentationCreationSearchParams } from '../../../services/buildPresentationCreationSearchParams';
import { useUpdateSearchCriteria } from '../hooks/useUpdateSearchCriteria';

export const ComparablesStep = () => {
  const searchCriteria = useSelector(({ search }: any) => search.searchCriteria);
  const client = useSelector(({ search }: any) => search.clients);
  const rebuildData = useSelector(({ report }: any) => report.rebuildData);
  const excludedIdsInResult = useSelector(({ result }: any) => result?.excluded || []);

  const history = useHistory();

  const [loading, setLoading] = useState(false);

  const { address, city, state, number, external } = useAddressFromQuery();

  const manualAddress = useAtomValue(manualAddressAtom);

  const [partialData, setPartialData] = useState(false);
  const [partialLoading, setPartialLoading] = useState(false);

  const dispatch = useDispatch();

  const setSearchParam = useSetSearchParameter();

  const setDefaultResultState = useSetDefaultResultState();

  const setResultRebuildState = useResultRebuildStateSetter();

  const {
    type,
    isRebuild,
    presentation: presentationHash,
    presentationId,
    action,
  } = usePresentationCreationQuery();

  const { getCreatePresentationPayload } = usePresentationCreator();
  const { mutateAsync: updatePresentation } = useUpdatePresentation(presentationHash);
  const { mutateAsync: updatePresentationWithoutSaving } =
    useUpdatePresentationWithoutSaving(presentationHash);

  const updateCriteria = useUpdateSearchCriteria();

  const getExclusions = (rebuildData: any) => {
    if (!rebuildData?.request?.excluded_ids) {
      return { excluded_ids: [] };
    }

    return {
      excluded_ids: rebuildData.request.excluded_ids,
    };
  };

  const onDetailsContinue = async (detailsCriteria: any, shouldBeSaved: boolean) => {
    try {
      const combinedCriteria = updateCriteria(detailsCriteria);

      const api = criteriaToApiMapper(combinedCriteria);
      const exclusions = getExclusions(rebuildData);
      const exclusionsFromMap = combinedCriteria.exclusions || [];
      const allExclusions = Array.from(
        new Set([...exclusions.excluded_ids, ...exclusionsFromMap, ...excludedIdsInResult]),
      );
      const reportDetailed = await ReportDetailedCommunicator.build({
        ...api,
        excluded_ids: allExclusions,
        presentation_type: type,
        presentation_id: !presentationId ? undefined : Number(presentationId),
        rebuild: isRebuild || undefined,
      });

      dispatch(operations.setApi({ ...api, excluded_ids: allExclusions }));
      dispatch(reportOperations.setReportDetailed(reportDetailed));
      setDefaultResultState(reportDetailed);

      const update = shouldBeSaved ? updatePresentation : updatePresentationWithoutSaving;

      if (isRebuild) {
        setResultRebuildState(reportDetailed, async () => {
          const presentationPayload = await getCreatePresentationPayload();

          await update(
            { ...presentationPayload, id: reportDetailed.presentationId },
            {
              onSuccess: async () => {
                if (client) {
                  await PresentationApi.updateClient(reportDetailed.presentationId, client);
                }

                await PresentationApi.triggerPdfGeneration(reportDetailed.presentationId);

                const params = buildPresentationCreationSearchParams({
                  type,
                  presentationId: reportDetailed.presentationId,
                  presentationHash: reportDetailed.presentationHash,
                  action,
                });

                if (shouldBeSaved) {
                  history.push(`/presentation/${reportDetailed.presentationHash}/edit`);
                  setLoading(false);
                  return;
                }

                history.push(`${URLS.RESULT}?${params}`);
                setLoading(false);
              },
            },
          );
        });

        return;
      }

      const presentationPayload = await getCreatePresentationPayload();
      await update({ ...presentationPayload, id: reportDetailed.presentationId });

      await PresentationApi.triggerPdfGeneration(reportDetailed.presentationId);

      if (shouldBeSaved) {
        history.push(`/presentation/${reportDetailed.presentationHash}/edit`);
        return;
      }

      const params = buildPresentationCreationSearchParams({
        type,
        presentationId: reportDetailed.presentationId,
        presentationHash: reportDetailed.presentationHash,
        action,
      });

      history.push(`${URLS.RESULT}?${params}`);
      setLoading(false);
    } catch (e) {
      console.error(e);
      toast(
        'We had trouble building report. Please try again. If the issue persists, please contact our support team.',
        {
          type: 'error',
          position: 'bottom-center',
        },
      );
      setLoading(false);
    }
  };

  const resetPartial = () => setPartialData(false);

  const partialReport = async (detailsCriteria: any) => {
    const combinedCriteria = {
      ...searchCriteria,
      ...detailsCriteria,
    };

    if (combinedCriteria.reportType === 'manual' && !combinedCriteria.manualMlsIds?.length) {
      setPartialData(false);
      return;
    }

    setPartialLoading(true);
    dispatch(operations.setSearchCriteria(combinedCriteria));
    const api = criteriaToApiMapper(combinedCriteria);
    const exclusions = getExclusions(rebuildData);
    const exclusionsFromMap = combinedCriteria.exclusions || [];
    const allExclusions = Array.from(
      new Set([...exclusions.excluded_ids, ...exclusionsFromMap, ...excludedIdsInResult]),
    );

    // @ts-ignore
    if (isNaN(parseInt(api?.bed_from))) {
      return; //not yet mounted;
    }

    try {
      const partialReport = await ReportDetailedCommunicator.buildPartial({
        ...api,
        excluded_ids: allExclusions,
        partial: true,
      });
      setPartialData(partialReport);
      dispatch(reportOperations.setReportDetailed({ subject: partialReport.subject }));
      dispatch(operations.setApi({ ...api, excluded_ids: allExclusions }));
    } catch (e) {
      setPartialData(false);
      toast(
        'We had trouble building partial report. Please try again. If the issue persists, please contact our support team.',
        {
          type: 'error',
          position: 'bottom-center',
        },
      );
    } finally {
      setPartialLoading(false);
    }
  };

  const onBackToAddress = () => {
    let params = qs.stringify({ address, city, state, number, type, external });

    if (!manualAddress) {
      params = qs.stringify({ address, city, state, number, type, step: 'info', external });
    }

    history.push({
      pathname: AuthenticatedRoutes.build,
      search: `?${params}`,
    });
  };

  return (
    <PresentationCreationStepperLayout>
      <StepsCard>
        <PropertyDetails
          // @ts-ignore
          onContinue={onDetailsContinue}
          partialLoading={partialLoading}
          partialData={partialData}
          partialReset={resetPartial}
          onPartial={partialReport}
          onBack={onBackToAddress}
          loading={loading}
          setLoading={setLoading}
        />
      </StepsCard>
    </PresentationCreationStepperLayout>
  );
};
