import { Dispatch, SetStateAction } from 'react';
import styled from 'styled-components';
import { OverrideForm } from './OverrideForm';

interface Props {
  onPrevious: () => void;
}

export const SubjectInfoOverride = ({ onPrevious }: Props) => {
  return (
    <Container>
      <Title>Confirm/Update subject property info</Title>
      <Subtitle>
        Information is provided by public records for the property and might not be
        current/accurate. Confirm and make adjustments as needed.
      </Subtitle>
      <OverrideForm onPrevious={onPrevious} />
    </Container>
  );
};

const Container = styled.div``;

const Title = styled.h3`
  color: ${({ theme }) => theme.colors.v2.text.regular};
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: uppercase;
`;

const Subtitle = styled.h4`
  color: ${({ theme }) => theme.colors.v2.text.regular};
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-bottom: 10px;
`;
