import styled from 'styled-components';
import { Controller, useFormContext, useWatch } from 'react-hook-form';

import states from '../../../../../../pages/PresentationCreate/dash/data/states';
import { MoneyFormatter } from '../../../../../../pages/PresentationCreate/dash/services/moneyFormatter';
import { NumberFormatter } from '../../../../../../pages/PresentationCreate/dash/services/numberFormatter';
import { propertyTypeOptions } from '../../../../../../pages/PresentationCreate/dash/view/pages/Authenticated/PropertySearch/PropertyDetails/PropertySearchCriteria/Criterias/PropertyType/propertyTypeOptions';
import { COMPARABLE_MODAL_MENU_ITEM_ID } from '../../../../../../types';
import { Select } from '../../../../../../components/Common';
import { Input } from '../../../../../../components/Common/V2/Input';
import { MainSection } from './MainSection';
import { AutosuggestAddresses } from './AutosuggestAddresses';
import NumberFormat from 'react-number-format';

const LISTING_STATUS_OPTIONS = [
  { label: 'Cooming Soon', value: 'coming_soon' },
  { label: 'Pending', value: 'pending' },
  { label: 'Active', value: 'active' },
  { label: 'Sold', value: 'sold' },
];

export const statesList = states.map(({ abbreviation }) => {
  return { value: abbreviation, label: abbreviation };
});

export const RequiredFields = () => {
  const {
    control,
    formState: { errors },
  } = useFormContext();

  const selectedStatus = useWatch({ name: 'listingStatus' });

  const hasErrors = Object.keys(errors).length > 0;

  return (
    <MainSection title='Required Fields' titleId={COMPARABLE_MODAL_MENU_ITEM_ID.REQUIRED_FIELDS}>
      {hasErrors && (
        <ErrorMessage>
          * One or more required fields are incomplete. Please ensure all required fields are filled
          out before creating an Off-MLS Comparable.
        </ErrorMessage>
      )}
      <Wrapper>
        <FormWrapper>
          <GridWrapper gridTemplateColumns='2fr 1fr'>
            <AutosuggestAddresses />
            <Controller
              name='aptOrSuite'
              control={control}
              defaultValue={null}
              render={({ field }) => <Input {...field} label='Apt or Suite #:' />}
            />
          </GridWrapper>
          <GridWrapper gridTemplateColumns='2fr 1fr 1fr'>
            <Controller
              name='city'
              control={control}
              render={({ field }) => (
                <Input
                  {...field}
                  label='City:'
                  required
                  error={errors.city ? (errors.city?.message as string) : undefined}
                  hideErrorMessage
                />
              )}
            />
            <Controller
              name='state'
              control={control}
              render={({ field }) => (
                <Select
                  {...field}
                  label='State:'
                  required
                  defaultValue={field.value}
                  options={statesList}
                  error={errors.state ? (errors.state?.message as string) : undefined}
                />
              )}
            />
            <Controller
              name='zip'
              control={control}
              render={({ field }) => (
                <Input
                  {...field}
                  label='ZIP:'
                  type='number'
                  required
                  error={errors.zip ? (errors.zip.message as string) : undefined}
                  hideErrorMessage
                />
              )}
            />
          </GridWrapper>
        </FormWrapper>
        <FormWrapper>
          <GridWrapper gridTemplateColumns='2fr 50%'>
            <Controller
              name='listingStatus'
              control={control}
              defaultValue={LISTING_STATUS_OPTIONS[2]}
              render={({ field }) => (
                <Select
                  {...field}
                  label='Listing Status:'
                  required
                  defaultValue={field.value}
                  options={LISTING_STATUS_OPTIONS}
                  error={
                    errors.listingStatus ? (errors.listingStatus?.message as string) : undefined
                  }
                />
              )}
            />
            <Controller
              name='propertyType'
              control={control}
              render={({ field }) => (
                <Select
                  {...field}
                  required
                  label='Property Type:'
                  defaultValue={field.value}
                  options={propertyTypeOptions}
                  error={errors.propertyType ? (errors.propertyType?.message as string) : undefined}
                />
              )}
            />
          </GridWrapper>
          <GridWrapper gridTemplateColumns='2fr 50%'>
            <Controller
              name='listPrice'
              control={control}
              render={({ field }) => (
                <NumberFormat
                  customInput={Input}
                  required
                  label='List Price:'
                  value={field.value || null}
                  onValueChange={({ floatValue }) => {
                    field.onChange(floatValue);
                  }}
                  onBlur={field.onBlur}
                  thousandSeparator={true}
                  prefix={'$'}
                  hideErrorMessage
                />
              )}
            />
            {selectedStatus && selectedStatus.value === 'sold' && (
              <Controller
                name='soldPrice'
                control={control}
                render={({ field }) => (
                  <NumberFormat
                    customInput={Input}
                    required
                    label='Sold Price:'
                    value={field.value || null}
                    onValueChange={({ floatValue }) => {
                      field.onChange(floatValue);
                    }}
                    onBlur={field.onBlur}
                    thousandSeparator={true}
                    prefix={'$'}
                    hideErrorMessage
                  />
                )}
              />
            )}
          </GridWrapper>
          <GridWrapper gridTemplateColumns='2fr 50%'>
            <Controller
              name='bedroomCount'
              control={control}
              render={({ field }) => (
                <Input
                  {...field}
                  label='Bedroom Count:'
                  type='number'
                  required
                  error={errors.bedroomCount ? (errors.bedroomCount.message as string) : undefined}
                  hideErrorMessage
                />
              )}
            />
            <Controller
              name='livingAreaSqft'
              control={control}
              render={({ field }) => (
                <NumberFormat
                  customInput={Input}
                  required
                  label='Living Area SQFT:'
                  value={field.value || null}
                  onValueChange={({ floatValue }) => {
                    field.onChange(floatValue);
                  }}
                  onBlur={field.onBlur}
                  thousandSeparator={true}
                  hideErrorMessage
                />
              )}
            />
          </GridWrapper>
        </FormWrapper>
      </Wrapper>
    </MainSection>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;
  padding-top: 20px;
`;

const FormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const GridWrapper = styled.div<{ gridTemplateColumns: string }>`
  display: grid;
  grid-template-columns: ${({ gridTemplateColumns }) => gridTemplateColumns};
  gap: 10px;
`;

const ErrorMessage = styled.span`
  color: ${props => props.theme.colors.v2.status.error};
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
  margin-top: 5px;
`;
