import { useMutation } from '@tanstack/react-query';

import { CustomComparablePayload, TProperty } from '../../../types';
import { address } from '../../../pages/PresentationCreate/dash/store/search/reducers';

export const useSaveCustomComparable = () => {
  return useMutation((payload: CustomComparablePayload): Promise<TProperty> => {
    return new Promise(resolve => {
      setTimeout(() => {
        resolve({
          ...payload,
          address: {
            state: payload.state,
            city: payload.city,
            deliveryLine: payload.delivery_line,
            zip: payload.zip,
          },
        } as unknown as TProperty);
      }, 1000);
    });
  });
};
