import * as yup from 'yup';

export type TRequiredComparableFormData = yup.InferType<typeof schema>;

export const schema = yup
  .object({
    address: yup.string().required(),
    city: yup.string().required(),
    state: yup
      .object({
        label: yup.string().required(),
        value: yup.string().required(),
      })
      .required()
      .test('state', 'state is invalid', function ({ value }) {
        if (!value) {
          return this.createError({
            message: 'state is invalid',
          });
        }
        return true;
      }),
    zip: yup
      .string()
      // .matches(/^\d{5}(-\d{4})?$/)
      .required(),
    listingStatus: yup
      .object({
        label: yup.string().required(),
        value: yup.string().required(),
      })
      .required()
      .test('listingStatus', 'listingStatus is invalid', function ({ value }) {
        if (!value) {
          return this.createError({
            message: 'listingStatus is invalid',
          });
        }
        return true;
      }),
    propertyType: yup
      .object({
        label: yup.string().required(),
        value: yup.string().required(),
      })
      .required()
      .test('propertyType', 'propertyType is invalid', function ({ value }) {
        if (!value) {
          return this.createError({
            message: 'propertyType is invalid',
          });
        }
        return true;
      }),
    listPrice: yup.string().min(1).required(),
    soldPrice: yup
      .string()
      .min(1)
      .when('listingStatus', {
        is: ({ value }: { value: string }) => value === 'sold',
        then: schema => schema.required(),
        otherwise: schema => schema.notRequired(),
      }),
    bedroomCount: yup.number().min(1).required(),
    livingAreaSqft: yup.string().min(1).required(),
  })
  .required();
