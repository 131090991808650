export class AddressAdapter {
  static apiToEntity(apiAddress: any) {
    return {
      state: apiAddress.state,
      zip: apiAddress.zip,
      city: apiAddress.city,
      deliveryLine: apiAddress.delivery_line,
      fullAddress: apiAddress?.label,
      external: apiAddress?.external,
    };
  }
}
