import { TCategory } from '../../../../../../../../../../types';
import { CATEGORIES } from '../../../../../../../../../../constants/categories';
import { ACCOUNT_IDS } from '../../../../../../../../../../constants/accountIds';

type TFont =
  | 'lato'
  | 'montserrat'
  | 'ubuntu'
  | 'bitter'
  | 'notoSans'
  | 'georgia'
  | 'roboto'
  | 'openSans'
  | 'sourceSans'
  | 'arial'
  | 'courierNew'
  | 'helvetica'
  | 'tahoma'
  | 'timesNewRoman'
  | 'trebuchetMS'
  | 'verdana'
  | 'lindenHill'
  | 'libreBaskerville'
  | 'workSans'
  | 'marcellus'
  | 'manrope'
  | 'myriadPro';

type TFontConfig = {
  id: TFont;
  label: string;
};

type TAvenirCustomFont = 'avenirLight';
type TLinetoBrownCustomFont = 'linetoBrown';
type TAkzidenzGrotesk = 'akzidenzGroteskLight' | 'akzidenzGrotesk';
type TGilroy = 'gilroyExtraBold' | 'gilroyLight';
type TLibreBaskerville = 'libreBaskerville';
type TWorkSans = 'workSans';
type TJost = 'jost';

type TCustomFont =
  | TAvenirCustomFont
  | TLinetoBrownCustomFont
  | TAkzidenzGrotesk
  | TGilroy
  | TLibreBaskerville
  | TWorkSans
  | TJost;

type TCustomFontConfig = {
  id: TCustomFont;
  label: string;
};

const fontConfigs: Record<TFont, TFontConfig> = {
  arial: {
    id: 'arial',
    label: 'Arial',
  },
  courierNew: {
    id: 'courierNew',
    label: 'Courier New',
  },
  georgia: {
    id: 'georgia',
    label: 'Georgia',
  },
  helvetica: {
    id: 'helvetica',
    label: 'Helvetica',
  },
  tahoma: {
    id: 'tahoma',
    label: 'Tahoma',
  },
  timesNewRoman: {
    id: 'timesNewRoman',
    label: 'Times New Roman',
  },
  trebuchetMS: {
    id: 'trebuchetMS',
    label: 'Trebuchet MS',
  },
  verdana: {
    id: 'verdana',
    label: 'Verdana',
  },
  bitter: {
    id: 'bitter',
    label: 'Bitter',
  },
  lato: {
    id: 'lato',
    label: 'Lato',
  },
  notoSans: {
    id: 'notoSans',
    label: 'Noto Sans',
  },
  roboto: {
    id: 'roboto',
    label: 'Roboto',
  },
  openSans: {
    id: 'openSans',
    label: 'Open Sans',
  },
  sourceSans: {
    id: 'sourceSans',
    label: 'Source Sans Pro',
  },
  montserrat: {
    id: 'montserrat',
    label: 'Montserrat',
  },
  ubuntu: {
    id: 'ubuntu',
    label: 'Ubuntu',
  },
  libreBaskerville: {
    id: 'libreBaskerville',
    label: 'Libre Baskerville',
  },
  lindenHill: {
    id: 'lindenHill',
    label: 'Linden Hill',
  },
  workSans: {
    id: 'workSans',
    label: 'Work Sans',
  },
  marcellus: {
    id: 'marcellus',
    label: 'Marcellus',
  },
  manrope: {
    id: 'manrope',
    label: 'Manrope',
  },
  myriadPro: {
    id: 'myriadPro',
    label: 'Myriad Pro',
  },
};

const avenirFontsConfig: TCustomFontConfig[] = [
  {
    id: 'avenirLight',
    label: 'Avenir Light',
  },
];

const linetoBrownFontsConfig: TCustomFontConfig[] = [
  {
    id: 'linetoBrown',
    label: 'Lineto Brown',
  },
];

const akzidenzGroteskConfig: TCustomFontConfig[] = [
  {
    id: 'akzidenzGroteskLight',
    label: 'Akzidenz Grotesk Light',
  },
  {
    id: 'akzidenzGrotesk',
    label: 'Akzidenz Grotesk Regular',
  },
];

const gilroyConfig: TCustomFontConfig[] = [
  {
    id: 'gilroyExtraBold',
    label: 'Gilroy Extra Bold',
  },
  {
    id: 'gilroyLight',
    label: 'Gilroy Light',
  },
];

const jostConfig: TCustomFontConfig[] = [
  {
    id: 'jost',
    label: 'Jost',
  },
];

const getCustomFontsConfig = (accountId: number): TCustomFontConfig[] => {
  switch (accountId) {
    case ACCOUNT_IDS.ILLUSTRATED_PROPERTIES:
      return [...avenirFontsConfig, ...linetoBrownFontsConfig];
    case ACCOUNT_IDS.THE_KEYES_COMPANY:
    case ACCOUNT_IDS.THE_KEYES_COMPANY_2:
      return linetoBrownFontsConfig;
    case ACCOUNT_IDS.DILBECK_REAL_ESTATE:
      return akzidenzGroteskConfig;
    case ACCOUNT_IDS.FRESH_LOOK_REAL_ESTATE:
      return gilroyConfig;
    case ACCOUNT_IDS.EQUITY_UNION:
      return jostConfig;
    // case ACCOUNT_IDS.NEXT_HOME:
    //   return [...libreBaskervilleConfig, ...workSansConfig];
    default:
      return [];
  }
};

const preferredCategoryFonts: Record<TCategory, TFont[]> = {
  [CATEGORIES.TRADITIONAL]: ['roboto', 'openSans', 'sourceSans'],
  [CATEGORIES.MODERN]: ['lato', 'montserrat', 'ubuntu'],
  [CATEGORIES.LUXURY]: ['bitter', 'notoSans', 'georgia'],
};

type TCategoryFontConfigs = {
  preferred: (TFontConfig | TCustomFontConfig)[];
  other: TFontConfig[];
};
export const getCategoryFonts = (
  accountId: number,
  category?: TCategory,
): TCategoryFontConfigs | null => {
  if (!category) return null;

  const customFonts = getCustomFontsConfig(accountId);

  const preferred = preferredCategoryFonts[category].map(p => fontConfigs[p]);
  const other = Object.values(fontConfigs).filter(
    fc => !preferredCategoryFonts[category].includes(fc.id),
  );

  return { preferred: [...customFonts, ...preferred], other };
};

export const getFont = (fontLabel: string) =>
  Object.values(fontConfigs).find(fc => fc.label === fontLabel);
