import { PropertyType, SearchAPI, SearchCriteria } from '../../../types';
import { PROPERTY_TYPE_OPTIONS } from '../constants/propertyTypeOptions';

const defaultRadius = 1;
const defaultMapMode = 'marker';
const defaultBathsFrom = 1;
const defaultBathsTo = 10;

const getDefaultSizeFrom = (size: number) => Math.round(size * 0.8);
const getDefaultSizeTo = (size: number) => Math.round(size * 1.2);

const defaultAdditionalIds: string[] = [];
const defaultExclusions: string[] = [];

const defaultYearBuiltTo = new Date().getFullYear();
const defaultTotalSizeSet = false;
const defaultTotalSizeUnit = 'sqft';
const totalSizeFrom = 0;
const totalSizeTo = 0;
const yearBuiltRangeSet = false;
const saleDateLimitFrom = 0;
const saleDateLimitTo = 6;

export class SearchCriteriaDefaultAdapter {
  public static toApi(propertyCriteria: SearchCriteria): SearchAPI | null {
    if (!propertyCriteria?.id) return null;

    const propertyTypeOption = PROPERTY_TYPE_OPTIONS.find(
      po => po.value === propertyCriteria.propertyType,
    );

    return {
      id: propertyCriteria.id,
      subject: {
        beds: propertyCriteria.beds,
        property_type: [[propertyTypeOption || PROPERTY_TYPE_OPTIONS[0]]],
        size: propertyCriteria.size,
        total_size: propertyCriteria.totalSize,
        coordinates: propertyCriteria.coordinates,
        year_built: propertyCriteria.yearBuilt,
        latitude: propertyCriteria?.coordinates?.latitude,
        longitude: propertyCriteria?.coordinates?.longitude,
        baths: propertyCriteria.totalBaths,
      },
      property_type: [
        (propertyCriteria.propertyType as PropertyType) || PROPERTY_TYPE_OPTIONS[0].value,
      ],
      bed_from: propertyCriteria.beds,
      bed_to: propertyCriteria.beds,
      total_baths_from: defaultBathsFrom,
      total_baths_to: defaultBathsTo,
      size_from: getDefaultSizeFrom(propertyCriteria?.size || 0),
      size_to: getDefaultSizeTo(propertyCriteria?.size || 0),
      total_size_set: defaultTotalSizeSet,
      total_size_from: totalSizeFrom,
      total_size_to: totalSizeTo,
      total_size_unit: defaultTotalSizeUnit,
      year_built_range_set: yearBuiltRangeSet,
      year_built_from: null,
      year_built_to: null,
      sale_date_limit_from: saleDateLimitFrom,
      sale_date_limit_to: saleDateLimitTo,
      additional_ids: defaultAdditionalIds,
      lat: propertyCriteria?.coordinates?.latitude,
      lon: propertyCriteria?.coordinates?.longitude,
      radius: defaultRadius,
      excluded_ids: defaultExclusions,
      ignore_size: false,
    };
  }

  public static toDefaultSearchCriteria(
    searchCriteriaState: SearchCriteria,
  ): Partial<SearchCriteria> | null {
    if (!searchCriteriaState?.id) return null;

    const propertyTypeOption = PROPERTY_TYPE_OPTIONS.find(
      po => po.value === searchCriteriaState.propertyType,
    );

    return {
      id: searchCriteriaState.id,
      beds: searchCriteriaState.beds,
      size: searchCriteriaState.size,
      baths: searchCriteriaState.baths,
      totalBaths: searchCriteriaState.totalBaths,
      radius: {
        label: String(defaultRadius),
        value: defaultRadius,
      },
      mapMode: defaultMapMode,
      bedsRange: [searchCriteriaState.beds, searchCriteriaState.beds],
      bathsRange: [defaultBathsFrom, defaultBathsTo],
      sizeRange: [
        getDefaultSizeFrom(searchCriteriaState?.size || 0),
        getDefaultSizeTo(searchCriteriaState?.size || 0),
      ],
      totalSize: searchCriteriaState.totalSize,
      yearBuilt: searchCriteriaState.yearBuilt,
      additional: defaultAdditionalIds,
      exclusions: defaultExclusions,
      coordinates: {
        latitude: searchCriteriaState.coordinates.latitude,
        longitude: searchCriteriaState.coordinates.longitude,
      },
      yearBuiltTo: defaultYearBuiltTo,
      manualMlsIds: [],
      propertyType: [propertyTypeOption || PROPERTY_TYPE_OPTIONS[0]],
      totalSizeSet: defaultTotalSizeSet,
      totalSizeUnit: defaultTotalSizeUnit,
      yearBuiltFrom: searchCriteriaState.yearBuilt,
      mapCoordinates: [
        {
          lat: searchCriteriaState.coordinates.latitude,
          lng: searchCriteriaState.coordinates.longitude,
        },
      ],
      totalSizeRange: [totalSizeFrom, totalSizeTo],
      yearBuiltRangeSet: yearBuiltRangeSet,
      saleDateLimitRange: [saleDateLimitFrom, saleDateLimitTo],
      propertyTypeSelections: [propertyTypeOption || PROPERTY_TYPE_OPTIONS[0]],
    };
  }
}
