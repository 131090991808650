import React, { useEffect, useState } from 'react';
import { GoogleMap } from '@react-google-maps/api';

import mapStyles from './mapStyles';
import Loader from '../../Loader/Loader';
import { MapOMSProvider } from './MapOMSProvider';
import { useGoogleMapLoaded } from './GoogleMapProvider';
import { MapScaleInverter } from '../../MapScaleInverter/MapScaleInverter';
import { attachScalableZoomControl } from '../../MapScaleInverter/ScalableZoomControl';

type TMapContainerStyle = {
  height: string | number;
  width: string | number;
};

type TCenter = {
  lat: number;
  lng: number;
};

interface GoogleMapComponentProps {
  mapContainerStyle: TMapContainerStyle;
  center?: TCenter;
  zoom?: number;
  options?: any;
  zoomLevel?: number;
  scaleFactor?: number;
  setMapInstance?: any;
  onClick?: (g: google.maps.MapMouseEvent) => void;
  children: React.ReactNode;
}

export const GoogleMapComponent: React.FC<GoogleMapComponentProps> = ({
  children,
  mapContainerStyle,
  center,
  zoom,
  zoomLevel,
  scaleFactor,
  setMapInstance = () => {},
  options,
  onClick,
}) => {
  const isLoaded = useGoogleMapLoaded();

  const [map, setMap] = useState<google.maps.Map | null>(null);

  const onMapLoad = React.useCallback(async (map: google.maps.Map) => {
    setMap(map);
    setMapInstance(map);

    if (!zoomLevel) {
      attachScalableZoomControl(map, scaleFactor);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onUnmount = React.useCallback(map => {
    setMap(null);
  }, []);

  useEffect(
    function onScaleFactorChange() {
      if (!zoomLevel) {
        attachScalableZoomControl(map, scaleFactor);
      }
    },
    [map, scaleFactor, zoomLevel],
  );

  useEffect(() => {
    function handleError(event: any) {
      if (event.message.includes('fromLatLngToDivPixel')) {
        event.preventDefault();
      }
    }

    window.addEventListener('error', handleError);
    return () => {
      window.removeEventListener('error', handleError);
    };
  }, []);

  const getOptions = () => {
    const def = {
      disableDefaultUI: true,
      styles: mapStyles,
      ...options,
    };
    if (!zoomLevel) {
      return def;
    }
    return {
      minZoom: 12,
      draggable: false,
      ...def,
    };
  };

  if (!isLoaded) return <Loader size='4x' />;

  return (
    <MapScaleInverter style={mapContainerStyle}>
      <GoogleMap
        mapContainerStyle={mapContainerStyle}
        mapContainerClassName='google-map'
        center={center}
        zoom={zoom}
        options={getOptions()}
        onLoad={onMapLoad}
        onUnmount={onUnmount}
        onClick={onClick}
      >
        <MapOMSProvider map={map}>
          <div>{children}</div>
        </MapOMSProvider>
      </GoogleMap>
    </MapScaleInverter>
  );
};
