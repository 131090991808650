import { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import styled from 'styled-components';
import { yupResolver } from '@hookform/resolvers/yup';

import {
  COMPARABLE_MODAL_MENU_ITEM_ID,
  COMPARABLE_MODAL_MENU_SUBITEM_ID,
  CustomComparableFormData,
  TProperty,
} from '../../../../types';
import { FitContentModal } from '../../../../components/Common';
import { schema } from '../../schema/schema';
import { SideMenu } from './SideMenu';
import { ComparablesModalContent } from './ComparablesModalContent/ComparablesModalContent';
import { customComparableFormDataToEntityMapper } from '../../services/customComparableFormDataToEntityMapper';

interface Props {
  isOpen: boolean;
  isLoading: boolean;
  onClose: () => void;
  onSave: (comparable: Partial<TProperty>, callback: () => void) => void;
}

export const CustomComparablesModal = ({ isOpen, isLoading, onClose, onSave }: Props) => {
  const [activeMainItemId, setActiveMainItemId] = useState<COMPARABLE_MODAL_MENU_ITEM_ID>(
    COMPARABLE_MODAL_MENU_ITEM_ID.REQUIRED_FIELDS,
  );
  const [activeSubItemId, setActiveSubItemId] = useState<COMPARABLE_MODAL_MENU_SUBITEM_ID | null>(
    null,
  );

  const methods = useForm({
    resolver: yupResolver(schema),
  });

  const { handleSubmit, reset } = methods;

  const handleSave = handleSubmit((values: CustomComparableFormData) => {
    console.log('values', values);
    const comparable = customComparableFormDataToEntityMapper(values);
    console.log('comparable', comparable);

    onSave(comparable, reset);
  });

  return (
    <FormProvider {...methods}>
      <FitContentModal
        isOpen={isOpen}
        headerTitle='Add OFF-MLS Comparable'
        onClose={onClose}
        confirmBtnText='Add Comparable'
        loading={isLoading}
        hasCancelBtn
        modalContainerStyles={{ maxWidth: 800, width: '80%', transform: 'translateY(-50%)' }}
        contentStyles={{ height: 'calc(100vh - 120px)' }}
        onConfirmClick={handleSave}
        disabled={false}
      >
        <Container>
          <SideMenu
            activeMainItemId={activeMainItemId}
            activeSubItemId={activeSubItemId}
            setActiveMainItemId={setActiveMainItemId}
            setActiveSubItemId={setActiveSubItemId}
          />
          <ComparablesModalContent
            setActiveMainItemId={setActiveMainItemId}
            setActiveSubItemId={setActiveSubItemId}
          />
        </Container>
      </FitContentModal>
    </FormProvider>
  );
};

const Container = styled.div`
  display: flex;
  height: 100%;
  overflow: hidden;
`;
