import React from 'react';
import { useSelector } from 'react-redux';

import styles from './AddressOverview.module.scss';
import { useAddressFromQuery } from '../../../../../../../../../features/presentation-creation-stepper/hooks/useAddressFromQuery';

export const AddressOverview = () => {
  const address = useSelector(({ search }) => search.address);

  const { address: addressFromQuery, city, state } = useAddressFromQuery();

  return (
    <h4 className={styles.addressOverview}>
      {addressFromQuery || address?.address},
      <span>
        {city || address?.city}, {state || address?.state?.value}
      </span>
    </h4>
  );
};
