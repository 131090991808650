import format from 'date-fns/format';
import { CustomComparableFormData, PROPERTY_STATUS, TProperty } from '../../../types';

export const customComparableFormDataToEntityMapper = (
  payload: CustomComparableFormData,
): Partial<TProperty> => {
  // TODO: Replace with real data
  return {
    // description: 'description',
    // source: 'manual',
    // excluded: false,
    mlsId: payload.mlsId,
    // mlsListingId: '1',
    propertyType: payload.propertyType.value,
    address: {
      deliveryLine: payload.address,
      fullAddress: payload.address,
      city: payload.city,
      state: payload.state.value,
      zip: payload.zip,
      external: true,
    },
    size: Number(payload.livingAreaSqft),
    // sizeDiff: 1,
    totalSize: payload.totalSize ? Number(payload.totalSize) : undefined,
    // beds: 1,
    totalBaths: payload.totalBaths ? Number(payload.totalBaths) : undefined,
    baths: payload.baths ? Number(payload.baths) : undefined,
    // daysOnMarket: 1,
    // daysToClose: 1,
    price: Number(payload.listPrice),
    // pricePerSqft: 1,
    salePrice: Number(payload.soldPrice),
    // salePricePerSqft: 1,
    yearBuilt: payload.yearBuilt ? Number(payload.yearBuilt) : undefined,
    coordinates: {
      latitude: 1, // TODO: get latitude
      longitude: 1, // TODO: get longitude
    },
    // distance: 1,
    // distanceKm: 1,
    // pool: false, // TODO: get pool
    // view: false, // TODO: get view
    status: payload.listingStatus.value as PROPERTY_STATUS,
    // images: [], // TODO: get images
    listDate: payload.listDate
      ? Math.floor(new Date(payload.listDate).getTime() / 1000)
      : undefined,
    listDateHuman: payload.listDate ? format(payload.listDate, 'M/d/yyyy') : undefined,
    saleDate: payload.saleDate
      ? Math.floor(new Date(payload.saleDate).getTime() / 1000)
      : undefined,
    saleDateHuman: payload.saleDate ? format(payload.saleDate, 'M/d/yyyy') : undefined,
    // partialMatch: false, // TODO: get partialMatch
    // partialMatchInfo: 'partialMatchInfo', // TODO: get partialMatchInfo
    // brokerName: 'brokerName', // TODO: get brokerName
    // basement: 'basement', // TODO: get basement
    // isWaterFront: false, // TODO: get isWaterFront
    // isWaterView: false, // TODO: get isWaterView
    // isDeck: false, // TODO: get isDeck
    // isFixerUpper: false, // TODO: get isFixerUpper
    // isNewConstruction: false, // TODO: get isNewConstruction
    // isAdult55: false, // TODO: get isAdult55
    // isGreenEnergyStar: false, // TODO: get isGreenEnergyStar
    // isHorseProperty: false, // TODO: get isHorseProperty
    // isGolfCourse: false, // TODO: get isGolfCourse
    // isAirConditioning: false, // TODO: get isAirConditioning
    // isMasterOnMain: false, // TODO: get isMasterOnMain
    // hoaFee: false, // TODO: get hoaFee
    // hoaFeeValue: 1, // TODO: get hoaFeeValue or null
    // hoaFeeFreq: 'hoaFeeFreq', // TODO: get hoaFeeFreq or null
    // garage: 1, // TODO: get garage
    // levels: 1, // TODO: get levels
    // saleType: 'saleType', // TODO: get saleType or undefined or null
    // disclaimer: 'disclaimer', // TODO: get disclaimer
    // singleImage: 'singleImage', // TODO: get singleImage or undefined or null
    // virtualTour: 'virtualTour', // TODO: get virtualTour
    // comparableExtensions: undefined, // TODO: get ComparableExtensions[]
    // adjustedPrice: undefined, // TODO: get adjustedPrice
    // note: undefined, // TODO: get note
    // adjustedPricePerSqft: undefined, // TODO: get adjustedPricePerSqft
    isCustom: true,
  };
};
