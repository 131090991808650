import React from 'react';
import styled from 'styled-components';
import { useLocation } from 'react-router-dom';

import { HeaderComponent } from '../../components/Common';
import { PresentationNavItem } from '../../types';

type WithScrollable = {
  isScrollable?: boolean;
};

const Container = styled('div')<WithScrollable>(({ theme, isScrollable }) => ({
  width: '100%',
  height: isScrollable ? 'auto' : '100vh',
  overflow: isScrollable ? 'visible' : 'hidden',
  display: 'flex',
  flexDirection: 'column',
  background: theme.colors.v2.gradient.background,
}));

interface Props {
  HeaderActions: JSX.Element;
  isScrollable?: boolean;
  navigationItems?: PresentationNavItem[];
}

export const PresentationBuildingLayout: React.FC<Props> = ({
  children,
  HeaderActions,
  isScrollable,
  navigationItems,
}) => {
  const location = useLocation();

  const isBuildReport =
    location.pathname.startsWith('/create') || location.pathname.startsWith('/build');
  const isCreatePresentation = location.pathname.startsWith('/presentation');

  const navItems = [
    { label: '1. Build CMA', isActive: isBuildReport },
    {
      label: '2. Create Presentation',
      isActive: isCreatePresentation,
    },
    { label: '3. Present & Share' },
  ];

  return (
    <Container isScrollable={isScrollable}>
      <HeaderComponent navItems={navigationItems ?? navItems}>{HeaderActions}</HeaderComponent>
      {children}
    </Container>
  );
};
