import {
  CSSProperties,
  ChangeEvent,
  FocusEvent,
  KeyboardEvent,
  ReactNode,
  forwardRef,
} from 'react';
import styled, { useTheme } from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

import { Icon } from '../Icon/Icon.component';

interface InputProps {
  type?: string;
  label?: string;
  name?: string;
  value?: string;
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
  onBlur?: (e: FocusEvent<HTMLInputElement>) => void;
  placeholder?: string;
  disabled?: boolean;
  required?: boolean;
  error?: string;
  leftIconName?: IconProp;
  leftIconStyles?: CSSProperties;
  rightIconName?: IconProp;
  wrapperStyle?: CSSProperties;
  inputWrapperStyle?: CSSProperties;
  labelStyle?: CSSProperties;
  inputStyle?: CSSProperties;
  innerSufix?: ReactNode;
  outerSuffix?: ReactNode;
  onLeftIconClick?: any;
  className?: string;
  onKeyUp?: (e: KeyboardEvent<HTMLInputElement>) => void;
  hideErrorMessage?: boolean;
}

export const Input = forwardRef<HTMLInputElement, InputProps>(
  (
    {
      type = 'text',
      label,
      name,
      value,
      onChange,
      onBlur,
      placeholder,
      disabled,
      required,
      error,
      leftIconName,
      rightIconName,
      wrapperStyle,
      inputWrapperStyle,
      labelStyle,
      inputStyle,
      innerSufix,
      outerSuffix,
      leftIconStyles,
      onLeftIconClick,
      className,
      onKeyUp,
      hideErrorMessage,
      ...props
    },
    ref,
  ) => {
    const { colors } = useTheme();

    const isDisabled = disabled && !required;
    const hasError = Boolean(error);

    const rightIconColor = isDisabled ? colors.v2.gray[200] : colors.v2.gray[300];
    const leftIconColor = isDisabled ? { color: colors.v2.gray[200] } : { color: colors.v2.accent };
    const innerSuffixStyle = innerSufix ? { borderRadius: '5px 0px 0px 5px' } : {};
    const outerSuffixStyle = outerSuffix ? { gap: '5px' } : {};

    return (
      <Wrapper style={{ ...wrapperStyle, ...outerSuffixStyle }} className={className}>
        <InputWrapper
          disabled={isDisabled}
          hasError={hasError}
          style={{ ...inputWrapperStyle, ...innerSuffixStyle }}
        >
          {leftIconName && (
            <FontAwesomeIcon
              onClick={e => onLeftIconClick(e)}
              icon={leftIconName}
              {...leftIconColor}
              style={{ width: 12, ...leftIconStyles }}
            />
          )}
          {(label || required) && (
            <LabelWrapper>
              {required && <Icon iconName='bt_required' fill={colors.v2.status.error} />}
              {label && (
                <Label htmlFor={name} style={labelStyle} disabled={isDisabled} hasError={hasError}>
                  {label}
                </Label>
              )}
            </LabelWrapper>
          )}
          <InputEl
            ref={ref}
            id={name}
            name={name}
            value={value}
            onChange={onChange}
            onBlur={onBlur}
            type={type}
            placeholder={placeholder}
            disabled={isDisabled}
            style={inputStyle}
            onKeyUp={onKeyUp}
            {...props}
          />
          {rightIconName && (
            <FontAwesomeIcon icon={rightIconName} color={rightIconColor} fontSize={12} />
          )}
        </InputWrapper>
        {innerSufix}
        {outerSuffix}
        {!hideErrorMessage && error && <ErrorText>{error}</ErrorText>}
      </Wrapper>
    );
  },
);

Input.displayName = 'Input';

const Wrapper = styled.div`
  display: flex;
  position: relative;
`;

const InputWrapper = styled.div<{ disabled?: boolean; hasError?: boolean }>`
  display: flex;
  align-items: center;
  gap: 10px;
  width: 100%;
  flex: 1;
  border-radius: 5px;
  border: ${props =>
    props.hasError
      ? `1px solid ${props.theme.colors.v2.status.error}`
      : props.disabled
        ? `1px solid ${props.theme.colors.v2.gray[200]}`
        : `1px solid ${props.theme.colors.v2.gray[300]}`};
  background: ${props => props.theme.colors.v2.background};
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.25) inset;
  padding: 0 10px;
  color: ${props =>
    props.disabled ? props.theme.colors.v2.gray[200] : props.theme.colors.v2.gray[400]};

  &:focus-within {
    border: double 1px transparent;
    background-origin: border-box;
    background-clip: padding-box, border-box;
    background-image: ${props => props.theme.colors.v2.gradient.border};
  }
`;

const LabelWrapper = styled.div`
  display: flex;
  gap: 5px;
`;

const Label = styled.label<{ disabled?: boolean; hasError?: boolean }>`
  color: ${props =>
    props.hasError
      ? props.theme.colors.v2.status.error
      : props.disabled
        ? props.theme.colors.v2.gray[200]
        : props.theme.colors.v2.gray[400]};
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 1;
  white-space: nowrap;
`;

const InputEl = styled.input`
  height: 33px;
  width: 100%;
  border: none;
  outline: none;
  box-shadow: none;
  background: transparent;
  color: ${props => props.theme.colors.v2.gray[400]};
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;

  ::placeholder {
    color: ${props => props.theme.colors.v2.gray[400]};
  }

  &:disabled {
    color: ${props => props.theme.colors.v2.gray[200]};
    cursor: not-allowed;

    ::placeholder {
      color: ${props => props.theme.colors.v2.gray[200]};
      opacity: 1;
    }
  }
`;

const ErrorText = styled.span`
  position: absolute;
  bottom: -14px;
  left: 0px;
  font-size: 11px;
  color: ${props => props.theme.colors.v2.status.error};
`;
