export const AuthenticatedRoutes = {
  settings: '/settings/customization',
  create: '/create', // TODO: deprecate
  presentationNew: '/presentation/new',
  presentationEdit: '/presentation/:hash/edit',
  presentationDashboard: '/presentation/:hash/dashboard',
  library: '/library',
  home: '/home',
  kvcoreReport: '/kvcore-report',
  analytics: '/admin-analytics/',
  analyticsOffice: '/admin-analytics/:office',
  analyticsUserOffice: '/admin-analytics/user/:hash/:office/:typeParam/:startParam/:endParam',
  build: '/build/address-search',
  details: '/build/details',
};

export const PublicRoutes = {
  sso: '/sso',
  presentationPreview: '/presentation/:hash',
  presentationPdfPreview: '/ssr/:hash',
  slidePreview: '/slide/:slideUuid',
  slidePdfPreview: '/slide_ssr',
  feedCheck: '/feed-check',
  whiteLabelLogin: '/whitelabel-login',
};

export const Routes = {
  root: '/',
  ...AuthenticatedRoutes,
  ...PublicRoutes,
};
