import { useMutation } from '@tanstack/react-query';

import { cmaApi } from '../../../pages/PresentationCreate/dash/communicators/cmaApiCommunicator';
import { ReportBuildCriteria, TPresentationType, TReportBuildPayload } from '../../../types';
import { getPresentationType } from '../../../services/getPresentationType';
import { ReportApiAdapter } from '../services/ReportApiAdapter';
import { SearchCriteriaDefaultAdapter } from '../services/SearchCriteriaDefaultAdapter';
import { useDispatch } from 'react-redux';
import { operations } from '../../../pages/PresentationCreate/dash/store/search';

const buildReport = async (criteria: ReportBuildCriteria, setAPI: (api: any) => void) => {
  const api = SearchCriteriaDefaultAdapter.toApi(criteria);

  // TO DO - Remove redux
  setAPI({ ...api, excluded_ids: [] });

  const payload = {
    ...api,
    excluded_ids: criteria.excludedIds,
    rebuild: criteria.rebuild,
    presentation_id: criteria.presentationId ? Number(criteria.presentationId) : undefined,
    presentation_type: getPresentationType(criteria.presentationType as TPresentationType),
  } as TReportBuildPayload;

  const apiReportDetailed = await cmaApi.post('/cma/build', ReportApiAdapter.entityToApi(payload));

  const report = ReportApiAdapter.apiToEntity(apiReportDetailed);

  return report;
};

export const useBuildReport = () => {
  // TO DO - Remove redux
  const dispatch = useDispatch();
  const setAPI = (api: any) => dispatch(operations.setApi(api));

  return useMutation((criteria: ReportBuildCriteria) => buildReport(criteria, setAPI), {
    onError: err => {
      console.error('Error while creating address', err);
    },
  });
};
