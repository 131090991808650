import Autosuggest, { SuggestionSelectedEventData, Theme } from 'react-autosuggest';
import styled, { CSSProperties } from 'styled-components';
import { Input } from '../V2/Input';
import { debounce } from 'debounce';

const getAutocompleteStyles = ({
  hasError,
  suggestionsContainerStyles,
  containerStyles,
}: {
  hasError: boolean;
  suggestionsContainerStyles?: CSSProperties;
  containerStyles?: CSSProperties;
}): Theme => {
  return {
    container: {
      position: 'relative',
      width: '100%',
      ...containerStyles,
    },
    inputFocused: {
      outline: 'none',
    },
    suggestionsContainer: {
      fontSize: 14,
      boxShadow: '0px 11px 40px 0 rgba(214, 214, 214, 0.11)',
      background: '#fff',
      maxHeight: 300,
      overflowY: 'auto',
      top: 'calc(100% + 1px)',
      borderRadius: '5px',
      fontWeight: 500,
      position: 'absolute',
      zIndex: 1,
      width: '100%',
      ...suggestionsContainerStyles,
    },
    suggestionsContainerOpen: {
      border: '1px solid #ddd',
      padding: '20px',
    },
    suggestionsList: {
      listStyleType: 'none',
    },
  };
};

const SuggestionItem = styled.div`
  padding: 10px;
  cursor: pointer;
  border-radius: 5px;
  &:hover {
    background-color: ${({ theme }) => theme.colors.v2.gray[100]};
  }
`;

const renderSuggestion = (suggestion: any) => <SuggestionItem>{suggestion.label}</SuggestionItem>;

interface Props {
  placeholder?: string;
  value?: string;
  required?: boolean;
  disabled?: boolean;
  label?: string;
  error?: string;
  onChange: (value: string) => void;
  onSuggestionSelected?: (suggestion: any) => void;
  asyncOptionsProvider?: (value: string) => void;
  suggestionsContainerStyles?: CSSProperties;
  suggestions: any[];
  containerStyles?: CSSProperties;
  hideErrorMessage?: boolean;
}

export const Autocomplete = ({
  placeholder,
  value = '',
  required,
  disabled,
  label,
  error,
  onChange,
  asyncOptionsProvider,
  suggestionsContainerStyles,
  onSuggestionSelected,
  suggestions,
  containerStyles,
  hideErrorMessage,
}: Props) => {
  const onSuggestionsFetchRequested = async ({ value }: { value: string }) => {
    if (!asyncOptionsProvider) return;
    debounce(asyncOptionsProvider, 500)(value);
  };

  const onSuggestionsClearRequested = () => {};

  const onValueChange = (event: any, { newValue }: { newValue: string }) => {
    onChange(newValue);
  };

  const inputProps = {
    placeholder,
    value: value || '',
    autoComplete: 'off',
    onChange: onValueChange,
  };

  return (
    <Container>
      <Wrapper>
        <Autosuggest
          suggestions={suggestions}
          onSuggestionsFetchRequested={onSuggestionsFetchRequested}
          onSuggestionsClearRequested={onSuggestionsClearRequested}
          getSuggestionValue={suggestion => suggestion.label}
          renderSuggestion={renderSuggestion}
          onSuggestionSelected={(
            event: React.FormEvent<any>,
            data: SuggestionSelectedEventData<any>,
          ) => {
            event.stopPropagation();
            onSuggestionSelected?.(data.suggestion);
          }}
          inputProps={inputProps as any}
          theme={getAutocompleteStyles({
            hasError: !!error,
            suggestionsContainerStyles,
            containerStyles,
          })}
          renderInputComponent={props => (
            <Input
              label={label}
              disabled={disabled}
              required={required}
              error={error}
              hideErrorMessage={hideErrorMessage}
              {...props}
            />
          )}
        />
      </Wrapper>
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
`;

const Wrapper = styled.div`
  position: relative;
`;
