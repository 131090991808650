import styled from 'styled-components';

interface Props {
  children: React.ReactNode;
}
export const StepsCard = ({ children }: Props) => {
  return <CardWrapper>{children}</CardWrapper>;
};

const CardWrapper = styled.div`
  background: #fff;
  border-radius: 10px;
  border: 1px solid ${({ theme }) => theme.colors.v2.gray[200]};
  box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.05);
`;
