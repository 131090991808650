import { useEffect, useState } from 'react';
import { useAtom } from 'jotai';
import { useHistory, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';

import { operations } from '../../../pages/PresentationCreate/dash/store/search';
import { operations as reportOperations } from '../../../pages/PresentationCreate/dash/store/report';
import { PresentationCreationStepperLayout } from '../layout/PresentationCreationStepperLayout';
import { manualAddressAtom } from './state/manualAddressAtom';
import { AuthenticatedRoutes } from '../../../pages/Router/Routes';
import { SubjectPropertyForm } from './subject-property-form/SubjectPropertyForm';
import { useAddressFromQuery } from '../hooks/useAddressFromQuery';
import { StepsCard } from '../components/StepsCard';

export const AddressSelectionStep = () => {
  const [manualAddress, setManualAddress] = useAtom(manualAddressAtom);
  const [overridePropertyStep, setOverridePropertyStep] = useState(false);

  const dispatch = useDispatch();

  const location = useLocation();
  const history = useHistory();
  const query = new URLSearchParams(location.search);

  const { address, city, state, number } = useAddressFromQuery();

  useEffect(() => {
    if (query.has('new')) {
      dispatch(operations.resetSearch());
      dispatch(reportOperations.resetReport());
      setOverridePropertyStep(false);
      setManualAddress(null);

      history.push(`${AuthenticatedRoutes.build}?type=${query.get('type')}`);
    }

    if (query.has('nonExistent') && manualAddress === null) {
      setManualAddress({
        address: address,
        city: city,
        number: number,
        state: state,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <PresentationCreationStepperLayout>
      <Container>
        <StepsCard>
          <FlexWrapper>
            <LeftSection>
              <PropertyInfoWrapper>
                <Title>PRICE A PROPERTY</Title>
                <Description>
                  Enter the address for the property you would like to price. Once entered, we will
                  use MLS data to provide accurate neighborhood comps.
                </Description>
                <Description>You can edit all of the comp criteria on the next step.</Description>
              </PropertyInfoWrapper>
            </LeftSection>

            <RightSection>
              <SubjectPropertyForm
                overridePropertyStep={overridePropertyStep}
                setOverridePropertyStep={setOverridePropertyStep}
              />
            </RightSection>
          </FlexWrapper>
        </StepsCard>
      </Container>
    </PresentationCreationStepperLayout>
  );
};

const Container = styled.div`
  margin-top: 20px;
  min-height: 100%;
  position: relative;
`;

const FlexWrapper = styled.div`
  display: flex;
  gap: 20px;
  padding: 30px 40px;

  @media (max-width: 991px) {
    flex-direction: column;
  }
`;

const LeftSection = styled.div`
  width: 33.33%;
  padding-right: 20px;
  display: flex;
  align-items: flex-start;

  @media (max-width: 991px) {
    width: 100%;
    border-right: none;
    border-bottom: 2px dashed ${({ theme }) => theme.colors.v2.gray[200]};
    margin: 0;
    padding: 0 0 20px 0;
  }
`;

const RightSection = styled.div`
  padding-left: 40px;
  width: 66%;
  display: flex;
  flex-direction: column;
  border-left: 2px dashed ${({ theme }) => theme.colors.v2.gray[200]};
  gap: 30px;

  @media (max-width: 991px) {
    width: 100%;
    padding-left: 0;
    border: none;
  }
`;

const PropertyInfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const Title = styled.h2`
  color: ${({ theme }) => theme.colors.gray[400]};
  font-size: 30px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
`;

const Description = styled.p`
  color: ${({ theme }) => theme.colors.gray[400]};
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
`;
