import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styled from 'styled-components';
import { InfoTooltip } from '../../../../../../../../../../components/Common/V2/InfoTooltip';
import { CSSProperties } from 'react';

interface Props {
  isActive: boolean;
  text: string;
  info: string;
  onClick?: () => void;
  inactiveStyles?: CSSProperties;
}

export const TabButton = ({ isActive, text, info, onClick, inactiveStyles }: Props) => {
  return (
    <Container onClick={onClick} style={!isActive ? inactiveStyles : {}}>
      <TextWrapper>
        <Text>{text}</Text>
        <InfoTooltip info={info} id={text} title={text} />
      </TextWrapper>
      <TabButtonIcon isOpen={isActive} icon='caret-down' />
    </Container>
  );
};

const Container = styled.div`
  padding: 15px 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
`;

const Text = styled.div`
  font-size: 14px;
  font-weight: 700;
  color: ${({ theme }) => theme.colors.v2.text.regular};
  width: 99px;
  text-transform: uppercase;
`;

const TabButtonIcon = styled(FontAwesomeIcon)<{ isOpen: boolean }>`
  transition: 0.5s;
  transform: ${({ isOpen }) => (isOpen ? 'rotate(0deg)' : 'rotate(180deg)')};
`;

const TextWrapper = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
`;
