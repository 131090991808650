import { useState } from 'react';

import { TProperty } from '../../../types';
import { Button } from '../../../components/Common/V2/Button/Button';
import { ManualComparablesContent } from '../components/ManualComparablesContent';
import { CustomComparablesModal } from './CustomComparablesModal/CustomComparablesModal';
import { useSaveCustomComparable } from '../hooks/useSaveCustomComparable';
import { customComparableEntityToApiMapper } from '../services/customComparableEntityToApiMapper';

export const OffMLSComparables = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [comparables, setComparables] = useState<TProperty[]>([]);

  const { mutateAsync, isLoading } = useSaveCustomComparable();

  const handleSave = async (comparable: Partial<TProperty>, callback: () => void) => {
    const payload = customComparableEntityToApiMapper(comparable);
    const response = await mutateAsync(payload);

    setComparables([...comparables, response]);
    setIsModalOpen(false);
    callback();
  };

  return (
    <ManualComparablesContent description='Property is Off-MLS: Manually add address and details.'>
      {comparables.map((comparable, index) => (
        <div key={index}>{comparable?.address?.deliveryLine}</div>
      ))}
      <Button variant='primary' style={{ width: '100%' }} onClick={() => setIsModalOpen(true)}>
        Add Off-MLS Comparable
      </Button>
      <CustomComparablesModal
        isOpen={isModalOpen}
        isLoading={isLoading}
        onClose={() => setIsModalOpen(false)}
        onSave={handleSave}
      />
    </ManualComparablesContent>
  );
};
