import { useLocation } from 'react-router-dom';

import { parseQueryParams } from '../services/parseQueryParams';

export const useAddressFromQuery = () => {
  const location = useLocation();

  const queryParams = parseQueryParams(location.search);

  const address = queryParams.address as string;
  const city = queryParams.city as string;
  const state = queryParams.state as string;
  const number = queryParams.number as string;
  const external = queryParams.external as string;

  return {
    address,
    city,
    state,
    number,
    external,
  };
};
