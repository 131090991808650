import styled from 'styled-components';
import { Input } from '../../../../../components/Common/V2/Input';
import { useMemo } from 'react';
import { CurrentUserService } from '../../../../../services/CurrentUserService';
import { Select } from '../../../../../components/Common';
import NumberFormat from 'react-number-format';
import { ADDRESS_CREATION_FIELDS, TAddressCreationFields } from '../../../../../types';
import { FEATURES, useHasFeatureFlag } from '../../../../../hooks/useFeatureFlags';
import { states } from '../../../../../constants/states';

type StateOption = { label: string; value?: string };

interface Props {
  fields: TAddressCreationFields;
  errors: TAddressCreationFields;
  onFieldChange: (field: ADDRESS_CREATION_FIELDS) => (value: string) => void;
}

export const ManualEntryContent = ({ fields, errors, onFieldChange }: Props) => {
  const isCanada = CurrentUserService.isCanadaUser();
  const hasBathCountFF = useHasFeatureFlag(FEATURES.CpSubjectBathCount);

  const { address, number, city, state, beds, size, yearBuilt, baths } = fields;

  const statesList = states.map(({ name, abbreviation }) => {
    return { value: abbreviation, label: name };
  });

  const defaultStateValue = useMemo(() => {
    return statesList?.find(({ value }) => value === state);
  }, [state, statesList]);

  return (
    <div>
      <Title>What is the address</Title>
      <Wrapper>
        <Grid style={{ gridTemplateColumns: '2fr 1fr' }}>
          <Input
            label='Address:'
            name='address'
            value={address}
            onChange={e => onFieldChange(ADDRESS_CREATION_FIELDS.ADDRESS)(e.target.value)}
            error={errors[ADDRESS_CREATION_FIELDS.ADDRESS]}
          />

          <Input
            label='Apt or Suite #:'
            name='apartment_number'
            value={number}
            onChange={e => onFieldChange(ADDRESS_CREATION_FIELDS.APT_NUMBER)(e.target.value)}
            error={errors[ADDRESS_CREATION_FIELDS.APT_NUMBER]}
          />
        </Grid>

        <Grid style={{ gridTemplateColumns: isCanada ? '1fr' : '1fr 1fr' }}>
          <Input
            label={isCanada ? 'Province' : 'City'}
            name='city'
            value={city}
            onChange={e => onFieldChange(ADDRESS_CREATION_FIELDS.CITY)(e.target.value)}
            error={errors[ADDRESS_CREATION_FIELDS.CITY]}
          />
          {!isCanada && (
            <div style={{ position: 'relative' }}>
              <Select
                placeholder='Options'
                defaultValue={defaultStateValue ? [defaultStateValue] : undefined}
                options={statesList}
                onChange={e =>
                  onFieldChange(ADDRESS_CREATION_FIELDS.STATE)((e as StateOption).value || '')
                }
              />
              {errors[ADDRESS_CREATION_FIELDS.STATE] && <ErrorText>{errors.state}</ErrorText>}
            </div>
          )}
        </Grid>

        <Grid hasBathCount={hasBathCountFF}>
          <Input
            type='number'
            label='Beds'
            name='beds'
            value={beds}
            onChange={e => onFieldChange(ADDRESS_CREATION_FIELDS.BEDS)(e.target.value)}
            error={errors[ADDRESS_CREATION_FIELDS.BEDS]}
          />
          {hasBathCountFF && (
            <Input
              type='number'
              label='Total Bath Count:'
              name='baths'
              value={baths}
              onChange={e => onFieldChange(ADDRESS_CREATION_FIELDS.BATHS)(e.target.value)}
              error={errors[ADDRESS_CREATION_FIELDS.BATHS]}
            />
          )}

          <NumberFormat
            customInput={Input}
            label='Living Area Sqft'
            name='size'
            value={size}
            placeholder={isCanada ? 'Optional' : ''}
            onValueChange={({ floatValue }) =>
              onFieldChange(ADDRESS_CREATION_FIELDS.SIZE)(String(floatValue))
            }
            error={errors[ADDRESS_CREATION_FIELDS.SIZE]}
            thousandSeparator={true}
          />
          <Input
            type='number'
            label='Year Built'
            name='yearBuilt'
            value={yearBuilt}
            onChange={e => onFieldChange(ADDRESS_CREATION_FIELDS.YEAR_BUILT)(e.target.value)}
            error={errors[ADDRESS_CREATION_FIELDS.YEAR_BUILT]}
          />
        </Grid>
      </Wrapper>
    </div>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
`;

const Title = styled.h3`
  color: ${props => props.theme.colors.v2.gray[400]};
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-bottom: 10px;
`;

const Grid = styled.div<{ hasBathCount?: boolean }>`
  display: grid;
  grid-template-columns: ${({ hasBathCount }) =>
    hasBathCount ? '1fr 1fr 1fr 1fr' : '1fr 1fr 1fr'};
  gap: 10px;

  @media (max-width: 1280px) {
    grid-template-columns: ${({ hasBathCount }) => (hasBathCount ? '1fr 1fr' : '1fr 1fr 1fr')};
  }

  @media (max-width: 768px) {
    grid-template-columns: 1fr !important;
  }
`;

const ErrorText = styled.span`
  position: absolute;
  bottom: -14px;
  left: 0px;
  font-size: 11px;
  color: ${props => props.theme.colors.v2.status.error};
`;
