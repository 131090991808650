import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styled from 'styled-components';
import ReactTooltip from 'react-tooltip';

interface Props {
  info: string;
  title?: string;
  id: string;
}
export const InfoTooltip = ({ info, title, id }: Props) => {
  return (
    <div>
      <span data-tip data-for={id}>
        <FontAwesomeIcon icon={['fal', 'circle-question']} />
      </span>
      <CustomTooltip
        id={id}
        place='right'
        effect='solid'
        getContent={() => (
          <TooltipContent>
            {title && <Title>{title}</Title>}
            <p>{info}</p>
          </TooltipContent>
        )}
      />
    </div>
  );
};

const CustomTooltip = styled(ReactTooltip)`
  max-width: 200px;
  padding: 12px;
  border-radius: 4px;
  background-color: rgba(68, 68, 68, 0.95) !important;
  box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.2);
  color: ${({ theme }) => theme.colors.v2.text.contrastText};
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 21px;

  &.__react_component_tooltip::after {
    display: none !important;
  }
`;

const TooltipContent = styled.div``;

const Title = styled.h3`
  font-size: 14px;
  font-weight: 700;
  color: ${({ theme }) => theme.colors.v2.text.contrastText};
  margin-bottom: 12px;
`;
